import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRippleEffect,
  IonRow,
  IonSpinner,
  IonTextarea,
} from '@ionic/react';
import {
  addOutline,
  closeOutline,
  locationSharp,
  pencilOutline,
  storefrontSharp,
} from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { updateStore } from '../../context/actions/store';
import { UPDATE_STORE_ERROR } from '../../context/actions/types';
import { GlobalContext } from '../../context/provider';
import { DeviceContext } from '../../deviceType';
import { Store, storeSchema } from '../../types/store';
import ListItem from './listItem';
import LocCard from './locationcard';
import classes from './styles.module.scss';
import { useFileUpload } from '../../hooks/files';
import { FolderEnum } from '../../utils/fileUpload';

const StoreInfoCard = (props: { data: storeSchema; action: () => void }) => {
  const size = useContext<any>(DeviceContext);
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const history = useHistory();
  const [edit, setEdit] = useState<boolean>(false);
  const bannerInput = useRef<any>();
  const profileInput = useRef<any>();
  const bannerInputRef = useRef<any>();
  const profileInputRef = useRef<any>();
  const [open, setOpen] = useState<{
    state: boolean;
    id: number | undefined;
    item: any;
    event: any;
  }>({ state: false, id: undefined, item: undefined, event: undefined });
  const [loading, setLoading] = useState<boolean>(false);

  const [store, setStore] = useState<Store>();
  // data
  const [locUpdate, setLocUpdate] = useState<any[]>([]);
  const [conUpdate, setConUpdate] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [update, setUpdate] = useState<Store>();
  const [banner, setBanner] = useState<any>(null);
  const [logo, setLogo] = useState<any>([]);
  const [error, setError] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const { uploadFile } = useFileUpload();

  // state mutators
  useEffect(() => {
    if (storeState!?.storeError) {
      history.push({
        pathname: '/err',
        state: {
          err: storeState!?.storeError.response?.data
            ? storeState!?.storeError.response.data.message
            : storeState!?.storeError.message,
          code: storeState!?.storeError.response?.status,
          from: '/store',
        },
      });

      storeDispatch({ type: UPDATE_STORE_ERROR, payload: null });
      setLoading(false);
    }
    if (storeState.updateStore) {
      const updatedStore = storeState.updateStore
      const tel = updatedStore.phoneNumber?.split(',') || [];
      const loc = updatedStore.location.split(',')?.map((item: string) => {
        return { name: item, coo: { lat: undefined, lng: undefined } };
      }) || [];

      setStore({
        ...updatedStore,
        location: locations
      });
      setBanner(null)
      setLocations([...loc]);
      setContacts([...tel!]);
    }
  }, [storeState.storeError, storeState.updateStore]);

  useEffect(() => {
    const tel = props.data.phoneNumber?.split(',') || [];
    const loc = props.data.location!.split(',')?.map((item) => {
      return { name: item, coo: { lat: undefined, lng: undefined } };
    }) || [];

    const ex: Store = {
      name: props.data.name!,
      logo: props.data.logo ? props.data.logo : undefined,
      banner: props.data.banner ? props.data.banner : undefined,
      email: props.data.email!,
      contacts,
      description: props.data.description!,
      type: props.data.type!,
      location: locations,
    };
    setStore(ex);
    setLocations([...loc]);
    setContacts([...tel!]);
  }, [props.data]);

  useEffect(() => {
    bannerInput.current?.addEventListener(
      'drop',
      (e: any) => {
        e.stopPropagation();
        e.preventDefault();
      },
      false
    );

    profileInput.current?.addEventListener(
      'drop',
      (e: any) => {
        e.stopPropagation();
        e.preventDefault();
      },
      false
    );
  }, []);

  const handleBannerUpload = async (banner: File) => {
    const uploadBanners = await uploadFile({
      files: [banner],
      folder: FolderEnum.Banners
    })
    if (uploadBanners?.error) {
      setError({
        error: true,
        message: uploadBanners.error
      })
      setLoading(false)
      return;
    }

    return uploadBanners
  }

  function toggleMode() {
    setUpdate(store);
    setLocUpdate([...locations]);
    setConUpdate(contacts!);
    setEdit(true);
  }

  const handleImageUpload = async (images: File[]) => {
    const uploadImages = await uploadFile({
      files: images,
      folder: FolderEnum.Logos,
    });
    if (uploadImages?.error) {
      setError({
        error: true,
        message: uploadImages.error,
      });
      setLoading(false);
      return;
    }

    return uploadImages;
  };

  async function saveChanges() {
    // send request to Back end to update Store Informatiion
    setLoading(true);
    setStore(update);
    setLocations(locUpdate);
    setContacts(conUpdate);
    const _store: Store = {
      email: update?.email!,
      location: locUpdate?.map((i) => {
        return i.name;
      })?.join(',')!,
      name: update?.name!,
      type: update?.type!,
      description: update?.description,
      phoneNumber: conUpdate?.join(',')!,
      logo: update?.logo,
      banner: store?.banner

    };

    if (banner) {
      const bannerurl = await handleBannerUpload(banner as File)
      _store.banner = bannerurl[0]
    }
    await updateStore({ id: props.data.id, store: _store })(storeDispatch);
    setEdit(false);
    setLoading(false);
  }
  const handleBanneronChange = (e: any) => {
    e.preventDefault()
    setBanner(e.target.files!?.[0])
  }

  function dimensions() {
    if (size.width > 769) {
      return { width: '13vw', height: '13vw', top: '-35%', left: ' 5%' };
    } else {
      if (size.width > 460) {
        return { width: '30vw', height: '30vw', top: '-35%', left: '5%' };
      } else {
        return { width: '40vw', height: '40vw', top: '-27%', left: '3%' };
      }
    }
  }
  return (
    <IonCard className={classes._card}>
      {loading && (
        <div className={classes.loading}>
          <IonSpinner name="circles" color={'secondary'}></IonSpinner>
        </div>
      )}
      {open.state && (
        <LocCard
          id={open.id}
          data={open.item}
          isOpen={open.state}
          setList={setLocUpdate}
          clickEvent={open.event}
          openCtx={() => {
            setOpen({
              state: !open.state,
              id: open.id,
              item: open.item,
              event: open.event,
            });
          }}
        />
      )}
      <div className={classes.headImg}>
        {edit && (
          <div
            className={
              classes.bannerInput + ' ion-activatable ripple-parent circle '
            }
            ref={bannerInput}
            onClick={(e) => {
              bannerInputRef.current.click();
            }}
          >
            <IonRippleEffect></IonRippleEffect>
            <IonIcon icon={addOutline} color={'light'}></IonIcon>
            <input
              id="banner"
              onChange={handleBanneronChange}
              type="file"
              ref={bannerInputRef}
              accept="image/png, image/jpg, image/jpeg"
            ></input>

            <p>Drag and Drop Image/Click to Open File Picker</p>
          </div>
        )}
        <div className={classes.overlay}></div>
        <img
          className={classes._img}
          alt="Store Head "
          src={
            edit
              ? update?.banner! instanceof File
                ? URL.createObjectURL(update?.banner!)
                : update?.banner
              : store?.banner! instanceof File
                ? URL.createObjectURL(store?.banner!)
                : store?.banner
          }
        />
      </div>
      {!edit && (
        <div className={classes._edit}>
          <IonButton fill="clear" onClick={toggleMode}>
            <IonIcon
              slot="icon-only"
              icon={pencilOutline}
              color="primary"
              size="small"
            ></IonIcon>
          </IonButton>
        </div>
      )}
      <IonCardContent className={classes._content}>
        <div
          className={classes._profile}
          style={{
            height: dimensions()?.height,
            top: dimensions()?.top,
            left: dimensions()?.left,
          }}
        >
          {/* Input Element */}
          {edit && (
            <div
              className={
                classes.profileInput + ' ion-activatable ripple-parent circle '
              }
              ref={profileInput}
              onClick={() => {
                profileInputRef.current!?.click();
              }}
            >
              <IonIcon icon={addOutline} color={'light'}></IonIcon>
              <IonRippleEffect></IonRippleEffect>
              <input
                id="profile"
                onChange={(e) => {
                  const s = { ...update!, logo: e.target.files!?.[0] };
                  setUpdate(s);
                  setLogo([e.target.files!?.[0]]);
                }}
                type="file"
                ref={profileInputRef}
                accept="image/png, image/jpg, image/gif, image/jpeg"
              ></input>
            </div>
          )}

          {
            <img
              className={classes._img}
              alt="Store Profile"
              src={
                edit
                  ? update?.logo! instanceof File
                    ? URL.createObjectURL(update?.logo!)
                    : update?.logo
                  : store?.logo! instanceof File
                    ? URL.createObjectURL(store?.logo!)
                    : store?.logo
              }
            />
          }
        </div>
        <div className={classes.left}>
          <IonGrid
            className={classes._grid}
            style={
              size.width > 769
                ? { top: '18%', padding: '0 1em' }
                : size.width > 460
                  ? { top: '10%', padding: '0 1em' }
                  : { top: '10%', padding: '0' }
            }
          >
            <IonRow
              className={classes._title}
              style={size.width < 769 ? { paddingInlineStart: '0' } : {}}
            >
              <IonCol
                size="12"
                style={size.width < 769 ? { fontSize: '1.5em' } : {}}
              >
                {!edit ? (
                  store?.name
                ) : (
                  <div>
                    <IonLabel color="primary">Title:</IonLabel>
                    <IonInput
                      type="text"
                      value={update?.name}
                      placeholder={'Title'}
                      onIonChange={(e) => {
                        setUpdate({ ...update!, name: e.detail?.value! });
                      }}
                    ></IonInput>
                  </div>
                )}
              </IonCol>
            </IonRow>

            {!edit && (
              <IonRow
                className={classes._type}
                style={size.width < 769 ? { paddingInlineStart: '3%' } : {}}
              >
                {/* Store Type */}
                <IonCol size="0.7" className={classes._icon}>
                  <IonIcon color="secondary" icon={storefrontSharp}></IonIcon>
                </IonCol>
                <IonCol size="10" className={classes._txt}>
                  {props.data.type}
                </IonCol>
              </IonRow>
            )}

            <IonRow className={classes._description}>
            <IonCol size="12">
            {!edit ? (
              store?.description
            ) : (
              <div className={classes.descinput}>
                <IonLabel>Description:</IonLabel>
                <IonTextarea
                  name="description"
                  autoGrow={true}
                  value={update?.description}
                  maxlength={256}
                  onIonChange={(e) => {
                    setUpdate({ ...update!, description: e.detail?.value! })
                  }}
                >
                </IonTextarea>
              </div>
            )}
            </IonCol>
            </IonRow>

            <IonRow
              className={classes._contact}
              style={
                size.width < 769
                  ? { paddingInlineStart: '0', height: edit ? '75%' : '65%' }
                  : { height: edit ? '47%' : '37%' }
              }
            >
              {/* Store Contacts */}
              <IonCol size="12" className={classes._holder}>
                <IonRow className={classes._email}>
                  <IonCol size="12">
                    {!edit ? (
                      store?.email
                    ) : (
                      <div>
                        <IonLabel color="primary">Email:</IonLabel>
                        <IonInput
                          type="text"
                          value={update?.email}
                          placeholder="Email"
                          onIonChange={(e) => {
                            setUpdate({ ...update!, email: e.detail?.value! });
                          }}
                        ></IonInput>
                      </div>
                    )}
                  </IonCol>
                </IonRow>

                <IonRow className={classes._tel}>
                  <IonCol size="12">
                    {!edit ? (
                      <ul className={classes.contactlist}>
                        {contacts!.map((item: any, id: number) => {
                          return <li key={id}>{item}</li>;
                        })}
                      </ul>
                    ) : (
                      <div
                        className={classes.contacts}
                        style={
                          size.width > 760
                            ? { width: ' 60%' }
                            : { width: ' 100%' }
                        }
                      >
                        <IonLabel color="primary">Contacts:</IonLabel>
                        <div className={classes.listHolder}>
                          <ul className={classes.listItems}>
                            {conUpdate!.map((item: any, index: number) => {
                              return (
                                <div className={classes._item} key={index}>
                                  <ListItem
                                    name={item}
                                    id={index}
                                    type={'tel'}
                                    setList={setConUpdate}
                                    openCtx={(e) => {
                                      setOpen({
                                        state: !open.state,
                                        item,
                                        event: e,
                                        id: index,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </ul>
                          <div
                            className={
                              classes.add +
                              ' ion-activatable ripple-parent rectangle'
                            }
                            onClick={() => {
                              setConUpdate([...conUpdate, '']);
                            }}
                          >
                            <IonRippleEffect></IonRippleEffect>
                            <p>Add</p>
                            <IonIcon
                              slot="end"
                              icon={addOutline}
                              color="secondary"
                            ></IonIcon>
                          </div>
                        </div>
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className={classes.right}>
          <IonGrid
            className={classes._holder}
            style={
              size.width > 769
                ? { paddingInlineEnd: '10%' }
                : { paddingInlineEnd: '0%', paddingInlineStart: '10%' }
            }
          >
            <IonRow className={classes._btn}>
              {/* Create Button */}
              <IonCol size="12">
                <div
                  style={
                    size.width > 769
                      ? {
                        width: edit ? '100%' : '50%',
                        justifyContent: edit ? 'flex-end' : 'start',
                      }
                      : {
                        width: '100%',
                        paddingInlineStart: edit ? '0' : '10%',
                        justifyContent: edit ? 'flex-end' : 'start',
                      }
                  }
                >
                  <IonButton
                    color="secondary"
                    expand="block"
                    fill="solid"
                    className={classes.save}
                    size={size.width > 769 ? 'default' : 'small'}
                    onClick={() => {
                      if (edit) {
                        // Submit Changes in the store Informatiion to Back end
                        saveChanges();
                      }
                      if (!edit) {
                        props.action();
                      }
                    }}
                  >
                    {!edit && (
                      <>
                        <IonIcon
                          slot="start"
                          color="light"
                          icon={addOutline}
                        ></IonIcon>
                        Create
                      </>
                    )}
                    {edit && (size.width > 769 ? <>Save Changes</> : <>Save</>)}
                  </IonButton>

                  {edit && (
                    <IonButton
                      color="dark"
                      fill="clear"
                      size={size.width > 769 ? 'default' : 'small'}
                      onClick={(e) => {
                        e.preventDefault();

                        setEdit(!edit);
                      }}
                      className={classes.cancel}
                    >
                      <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                  )}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className={classes._loc}>
              {/* Locations */}
              <IonCol size="12">
                {edit ? (
                  <div
                    className={classes.edit_holder}
                    style={
                      size.width > 760 ? { width: ' 65%' } : { width: ' 100%' }
                    }
                  >
                    <div className={classes.locations}>
                      <IonLabel position="floating" color="primary">
                        Locations
                      </IonLabel>
                      <div className={classes.list}>
                        {locUpdate!?.map((item, index) => {
                          return (
                            <div className={classes._item} key={index}>
                              <ListItem
                                name={item.name}
                                id={index}
                                type={'loc'}
                                setList={setLocUpdate}
                                openCtx={(e) => {
                                  setOpen({
                                    state: !open.state,
                                    item,
                                    event: e,
                                    id: index,
                                  });
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className={
                        classes._add + ' ion-activatable ripple-parent circle'
                      }
                      onClick={(e) => {
                        setOpen({
                          state: !open.state,
                          item: undefined,
                          event: e,
                          id: undefined,
                        });
                      }}
                    >
                      <IonRippleEffect color="primary"></IonRippleEffect>
                      Add
                      <IonIcon
                        slot="end"
                        icon={addOutline}
                        color="secondary"
                      ></IonIcon>
                    </div>
                  </div>
                ) : (
                  <div
                    className={classes._locations}
                    style={
                      size.width > 769
                        ? { width: '50%' }
                        : { width: '100%', paddingInlineStart: '10%' }
                    }
                  >
                    <div className={classes._label}>
                      <IonIcon
                        color="secondary"
                        icon={locationSharp}
                        slot="start"
                        size="medium"
                      ></IonIcon>
                      Locations
                    </div>
                    <div className={classes._list}>
                      <ul>
                        {locations!.map((item, id) => {
                          return <li key={id}>{item.name}</li>;
                        })}{' '}
                      </ul>
                    </div>
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default StoreInfoCard;




