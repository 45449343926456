import React, { useState, } from 'react';
import classes from './styles.module.scss';
import Footer from '../../../components/Layout/user/footer';
import Nav from '../../../components/Layout/user/nav';
import { IonButton, IonIcon, IonImg } from '@ionic/react';
import landing_img from '../../../Assets/landing_image.svg';
import { useHistory } from 'react-router';
import ContactUsForm from '../../../components/contactusForm/contact';
import FaqsItem from '../../../components/Faqs';
import { FaqsType } from '../../../types/faqs';
import { IoMdArrowDropright } from 'react-icons/io';
import { getCurrentUser } from '../../../utils/getClientInfo';
import { faqs } from '../../../data/faqs';
import { Link } from "react-router-dom";
import BecomeASeller from '../../../components/becomeSellerModal';

const Mobile = ({ data, video }: { data: any; video: any }) => {
  const history = useHistory();
  const userData = getCurrentUser();
  const isLoggedIn = !!Object.keys(userData).length;
  const isVendor = isLoggedIn && userData?.role === 'seller';
  const isBuyer = isLoggedIn && userData?.role === 'user';
  const hasStore = isVendor && userData?.stores?.length;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const filterQuestions = faqs.filter((item: FaqsType, index: number) => {
    return index <= 3;
  });

  
  const onCreateStoreBtnClick = () => {
    if (!isLoggedIn) {
      history.push('/registervendor');
    } else if (isBuyer) {
      setIsModalOpen(true)
    } else if (isVendor && !hasStore) {
      history.push('/dashboard/create-store');
    } else if (isVendor && hasStore) {
      history.push('/dashboard/store');
    }
  };

  const getCreateStoreBtnText = () => {
    if (isLoggedIn && isBuyer) {
      return 'Become a Vendor';
    } else if (isLoggedIn && isVendor && hasStore) {
      return 'Manage your store';
    }
    return 'Add your shop';
  }
  
  return (
    <React.Fragment>
      
      {/* Become vendor modal */}
      <BecomeASeller
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />

      <div className={classes.landing_nav}>
        <Nav backgroundColor="primary" />
      </div>
      <div className={classes.landing_mobile}>
        <div className={classes.content}>
          <div className={classes.typography}>
            <div className={classes.primary}>{data.showcase.primary}</div>
            <div className={classes.secondary}>{data.showcase.secondary}</div>
            <div className={classes.links}>
              <IonButton
                className={classes.btn}
                fill="solid"
                color="secondary"
                onClick={onCreateStoreBtnClick}
              >
                { getCreateStoreBtnText() }
              </IonButton>
              { 
                !isLoggedIn &&
                <IonButton
                  className={classes.btn}
                  fill="clear"
                  color="secondary"
                  routerLink="/signup"
                >
                  Join as a Shopper
                </IonButton>
              }
            </div>
          </div>
          <div className={classes.showcase}>
            <div className={classes.imageHolder}>
              <div className={classes.img}>
                <IonImg src={landing_img}></IonImg>
              </div>
            </div>
            <div className={classes.statHolder}>
              {data.showcase.showcase_stat.map((item: any, index: number) => (
                <div className={classes.statItem} key={index}>
                  <div className={classes.label}>{item.label}</div>
                  <div className={classes.icon}>
                    <IonIcon icon={item.icon} color="light"></IonIcon>
                  </div>
                  <div className={classes.statistic}>{item.statistic}</div>
                  <div className={classes.description}>{item.description}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.whyus}>
            <div className={classes.label}>{data.whyus.label}</div>
            <div className={classes.sub}>{data.whyus.sub}</div>
            <ul className={classes.list}>
              {(data.whyus.list as any[]).map((item: any, index) => {
                return (
                  <li className={classes.listitem} key={index}>
<IoMdArrowDropright color="#A94064" />
<IoMdArrowDropright color="#A94064" />

                    <IoMdArrowDropright color="#A94064" />

                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={classes.becomevendor}>
            <div className={classes.label}>{data.becomevendor.label}</div>
            <div className={classes.sub}>{data.becomevendor.sub}</div>
            <div className={classes.video}>
              <video
                ref={video}
                src={data.becomevendor.video}
                controls
                loop
                autoPlay={true}
              ></video>
            </div>
            {/* <div className={classes.steps}>
              {(data.becomevendor.steps as any[]).map((item: any, index) => {
                return (
                  <div className={classes.steps_container} key={index}>
                    <div
                      className={
                        classes.head + ' ion-activatable ripple-parent circle'
                      }
                      onClick={() => {
                        history.push(item.path);
                      }}
                    >
                      <IonRippleEffect></IonRippleEffect>
                      <div className={classes.icon}>{item.icon}</div>
                      <div className={classes.label}>{item.label}</div>
                    </div>
                    <div className={classes.body}>{item.text}</div>
                  </div>
                );
              })}
            </div> */}
          </div>
          <div className={classes.contact}>
          <div className={classes.label}>{data.contact.label}</div>
            <div className={classes.contactHolder}>
              <ContactUsForm
              />
            </div>
          </div>
          <div className={classes.faqs}>
            <div className={classes.label}>{data.faqs.label}</div>
            <div className={classes.faqsContainer}>
              {/* {data.faqs.faq.map((item: FaqsType, index: any) => {
                return (
                  <div className={classes.faqItem} key={`home_faqs_${index}`}>
                    <FaqsItem title={item.title} content={item.content} />
                  </div>
                );
              })} */}
              {filterQuestions.map((item: FaqsType, index:number) => {
              return <FaqsItem
                key={index}
                title={item.title}
                content={item.content}
               />
             })}
            </div>
            <div className={classes.more}>
             <Link to="/faqs">view all</Link>
            </div>
          </div>
        </div>
        <div className={classes.footer_landing}>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Mobile;
