import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonIcon,
  IonSpinner,
  IonButtons,
  IonText,
  IonRow,
  IonCol,
  IonButton,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import classes from './styles.module.scss';
import { GlobalContext } from '../../context/provider';
import { BECOMESELLER_ERROR } from '../../context/actions/types';
import logo from '../../Assets/logo/logo_white_background_96.svg';
import { BecomeSeller } from '../../context/actions/user';
import { DeviceContext } from '../../deviceType';

const BecomeASeller: React.FC<{
  isOpen: boolean;
  setIsOpen: (e: any) => void;
}> = ({ isOpen, setIsOpen }) => {
  const history = useHistory();
  const { userState, userDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [becomeVendorError, setBecomeVendorError] = useState<string | null>(null);
  const device = useContext(DeviceContext);

  const onDidDismiss = () => {
    if (becomeVendorError) {
      userDispatch({ type: BECOMESELLER_ERROR, payload: null });
    }
  };
  
  const closeBecomeSellerModal = () => {
    setIsOpen(false);
    history.push('/dashboard/create-store');
    window.location.reload();
  };

  const getSellerToken = async () => {
    setLoading(true);
    await BecomeSeller()(userDispatch);
    setLoading(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  
  useEffect(() => {
    if (userState.BecomeSeller) {
      closeBecomeSellerModal();
    }
    if (userState.BecomeSellerError) {
      const errMessage = userState.BecomeSellerError.response?.data?.message 
        ?? userState.BecomeSellerError?.message;
      setBecomeVendorError(errMessage);
    }
  }, [userState.BecomeSellerError, userState.BecomeSellerError]);

  return (
    <IonModal
      className={device?.width > 600 ? classes['desktop-modal'] : classes['mobile-modal']}
      isOpen={isOpen}
      canDismiss={!loading}
      onDidDismiss={onDidDismiss}
    >
      <IonHeader className={classes.header}>
        <IonToolbar>
          <IonText>
            <IonIcon src={logo}></IonIcon>
            <span className={classes.span1}>Bazaar</span>
            <span className={classes.span2}>Cheap</span>
          </IonText>
          { !loading &&
            <IonButtons slot="end">
              <IonIcon
                className={classes['close-icon']}
                src={closeOutline}
                onClick={() => setIsOpen(false)}
              ></IonIcon>
            </IonButtons>
          }
        </IonToolbar>
      </IonHeader>

      <IonContent className={classes.content}>
        <h2 className='ion-text-center'>Become a Vendor</h2>

        <div className={classes['msg-wrapper']}>
          <p>
            You are about to become a vendor on BazaarCheap. You will be able to create and manage your store(s), products and orders.
            <span className={classes['proceed-text']}> Click the button below to proceed.</span>
          </p>
        </div>
        
        {loading && (
          <div className={classes['msg-wrapper']}>
            <p color="secondary">Your vendor account is being set up. Please wait...</p>
            <IonSpinner name="lines" />
          </div>
        )}
        
        {becomeVendorError && (
          <div className={classes['msg-wrapper']}>
            <p className={classes.error}>Error: {becomeVendorError}</p>
          </div>
        )}

        <div className={classes[device?.width > 600 ? 'btns': 'desktop-btns']}>
          <IonRow>
            <IonCol>
              <IonButton shape="round" className={classes['cancel-btn']} disabled={loading} onClick={handleCancel}>Cancel</IonButton>
            </IonCol>
            <IonCol>
              <IonButton shape="round" onClick={getSellerToken}>Continue</IonButton>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default BecomeASeller;
