import React, { useContext, useEffect } from 'react';
import Layout from '../../../components/Layout/user/Layout';
import classes from './styles.module.scss';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import WishListCard from '../../../components/cards/wish-list';
import { GlobalContext } from '../../../context/provider';
import { getWishList } from '../../../context/actions/products';

const WishList = () => {
  const size = useContext(DeviceContext);
  const { productState, productDispatch } = useContext<any>(GlobalContext);

  useEffect(() => {
    getWishList()(productDispatch);
  }, []);

  const wishlistProducts = productState.wishlist;

  return (
    <Layout>
      <IonGrid className={classes.wishlistContainer}>
        <IonRow>
          <IonCol>
            <div className={classes.head}>
              <div className={classes.title}>Wish List</div>
            </div>
            <div
              className={classes.content}
              style={{ justifyContent: size.width < 568 ? 'center' : '' }}
            >
              {wishlistProducts?.map((item: any, index: number) => {
                return <WishListCard wishlistItems={item} key={index} />;
              })}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Layout>
  );
};

export default WishList;
