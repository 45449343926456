import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
} from '@ionic/react';
import { text, searchSharp, filter, options } from 'ionicons/icons';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import ProductBlock from '../../../components/cards/Product_Block';
import ProductFlex from '../../../components/cards/Product_Flex';
import DisplaySegment from '../../../components/displaySegment';
import Layout from '../../../components/Layout/user/Layout';
import { searchProducts } from '../../../context/actions/products';
import {
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  getProductsParams,
} from '../../../context/actions/types';
import { GlobalContext } from '../../../context/provider';
import { DeviceContext } from '../../../deviceType';
import Filters from '../../../components/searchFilters';
import Loading from './loading';
import SearchField from '../../../components/searchField';
import classes from './styles.module.scss';

function resultsReducer(state: any, action: { type: string; payload: any }) {
  switch (action.type) {
    case 'setResults':
      return { data: [...action.payload] };
    default:
      return state;
  }
}
function localParamsReducer(
  state: any,
  action: { type: string; payload: any }
) {
  return { ...state, ...action.payload };
}

const SearchResults = () => {
  const history = useHistory();
  const location = useLocation();
  const size = useContext(DeviceContext);
  const { productState, productDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [listStyle, setListStyle] = useState<'flex' | 'grid'>('grid');
  const [results, resultsDispatch] = useReducer(resultsReducer, { data: [] });
  const [params, setParams] = useState<getProductsParams>({});
  const [localParams, localParamsDispatch] = useReducer(localParamsReducer, {});
  const [extractedParams, setExtractedParams] = useState<any>({});
  const filterModal = useRef<any>();

  useEffect(() => {
    const query = location.state instanceof Object ? location.state : {};
    setParams(query);
  }, []);

  useEffect(() => {
    if (JSON.stringify(params) !== '{}') {
      fetchProducts();
    }
  }, [params]);

  useEffect(() => {
    if (productState.searchProducts != null) {
      const p = extractParams();
      setExtractedParams({
        priceBelow: p.highestPrice,
        priceAbove: p.lowestPrice,
        location: p.locations,
        categories: p.category,
      });
      resultsDispatch({
        type: 'setResults',
        payload: productState.searchProducts.data,
      });
      setLoading(false);
      productDispatch({ type: SEARCH_PRODUCTS, payload: null });
    }
    if (productState.searchProductsError != null) {
      history.replace({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: productState.searchProductsError!?.response!?.data!?.message
            ? productState.searchProductsError!?.response!?.data!?.message
            : productState.searchProductsError!?.message,
          code: productState.searchProductsError.request.status
            ? productState.searchProductsError.request.status
            : productState.searchProductsError.status,
        },
      });
      productDispatch({ type: SEARCH_PRODUCTS_ERROR, payload: null });
    }
  }, [productState]);

  function extractParams() {
    const high = Math.max.apply(
      null,
      productState.searchProducts.data.map((item: any) => {
        return item.price;
      })
    );
    const low = Math.min.apply(
      null,
      productState.searchProducts.data.map((item: any) => {
        return item.price;
      })
    );
    const locations = productState.searchProducts.data
      .map((item: any, i: number, arr: any[]) => {
        const locs = item.store.location?.split(',');
        return locs;
      })
      .flat()
      .filter(
        (item: any, index: number, arr: any[]) => arr.indexOf(item) === index
      );
    const query: any = location.state instanceof Object ? location.state : {};
    let categories = [query.categories!];
    if (localParams.categories) {
      categories = [...categories, ...localParams.categories];
    }
    return {
      lowestPrice: low,
      highestPrice: high,
      locations,
      category: categories,
    };
  }

  async function fetchProducts() {
    setLoading(true);
    await searchProducts(params)(productDispatch);
  }

  return (
    <Layout>
      <div className={classes.main}>
        {loading ? (
          <Loading cards={6} />
        ) : size.width >= 768 ? (
          <IonGrid className={classes.searchGrid}>
            <IonRow className={classes.holder}>
              <IonCol className={classes.leftBar} size={'3'}>
                <IonRow className={classes.topBar}>
                  <div className={classes.title}>Filter By</div>
                </IonRow>
                <IonRow className={classes.filters}>
                  <IonCol>
                    <Filters
                      setParams={localParamsDispatch}
                      categories={true}
                      locations={true}
                      priceRange={true}
                      reducer={true}
                      params={{
                        local: localParams,
                        extracted: extractedParams,
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol className={classes.rightBar} size={'9'}>
                <IonRow className={classes.topBar}>
                  <div className={classes.segment}>
                    <DisplaySegment action={setListStyle} />
                  </div>
                </IonRow>
                <IonRow className={classes.content}>
                  <IonCol size="12">
                    <div className={classes.searchField}>
                      <SearchField
                        value={params.text!}
                        setValue={setParams}
                        action={() => {
                          setLoading(true);
                          setParams((e: any) => {
                            return { ...e, ...localParams };
                          });
                        }}
                      />
                    </div>
                    {results.data?.length > 0 ? (
                      <div className={classes.searchResults}>
                        {results.data.map((item: any, index: number) => {
                          let { store, ...rest } = item;
                          rest = {
                            ...rest,
                            store: store.name,
                            location: store.location?.split(',')[0],
                            title: rest.name,
                          };
                          return listStyle === 'flex' ? (
                            <ProductFlex data={rest} key={index} />
                          ) : (
                            <ProductBlock data={item} key={index} />
                          );
                        })}
                      </div>
                    ) : (
                      <div className={classes.noResults}>No Results Found</div>
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <IonGrid className={classes.mobileSearchGrid}>
            <IonRow className={classes.headBar}>
              <IonCol size={'12'}>
                <div className={classes.searchBar}>
                  <SearchField
                    value={params.text!}
                    setValue={setParams}
                    action={() => {
                      setParams((e: any) => {
                        return { ...e, ...localParams };
                      });
                    }}
                  />
                </div>
                <div className={classes.filters}>
                  <span>Filters</span>
                  <span>
                    <IonButton color="dark" id={'filter-btn'} fill="clear">
                      <IonIcon slot="icon-only" icon={options}></IonIcon>
                    </IonButton>
                    <IonModal
                      ref={filterModal}
                      draggable
                      trigger="filter-btn"
                      id={'filterModal'}
                      animated
                      handle
                      style={{
                        overflowY: 'scroll',
                        padding: ' 0.5em 1em',
                      }}
                      showBackdrop
                      initialBreakpoint={0.5}
                      breakpoints={[0, 0.25, 0.5, 0.75]}
                    >
                      <div className={'topBar'}>
                        <div>Filters</div>
                      </div>
                      <div style={{ width: '100%', height: 'auto' }}>
                        <Filters
                          setParams={localParamsDispatch}
                          categories={true}
                          locations={true}
                          reducer={true}
                          priceRange={true}
                          params={{
                            local: localParams,
                            extracted: extractedParams,
                          }}
                        />
                      </div>
                    </IonModal>
                  </span>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className={classes.content}>
              <IonCol size="12">
                {results.data.length > 0 ? (
                  <>
                    <div className={classes.displaySegment}>
                      <div className={classes.segment}>
                        <DisplaySegment action={setListStyle} />
                      </div>
                    </div>
                    <div className={classes.results}>
                      {results.data.map((item: any, index: number) => {
                        let { store, ...rest } = item;
                        rest = {
                          ...rest,
                          store: store.name,
                          location: store.location?.split(',')[0],
                          title: rest.name,
                        };

                        return listStyle === 'flex' ? (
                          <ProductFlex data={rest} key={index} />
                        ) : (
                          <ProductBlock data={rest} key={index} />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className={classes.noResults}>No Results Found</div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </div>
    </Layout>
  );
};

export default SearchResults;
