import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import { IonGrid, IonRow, IonCol, IonSpinner } from '@ionic/react';
import ProductMetrics from './metricsCard';
import ItemDetailsCard from '../../../components/productDetailsCard';
import { useHistory, useLocation } from 'react-router';
import { GlobalContext } from '../../../context/provider';
import { getSingleProduct } from '../../../context/actions/products';
import {
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_ERROR,
} from '../../../context/actions/types';
import { ProductType } from '../../../types/product';
import Spinner from '../../../components/spinner';

const ProductSeller = () => {
  const size = useContext(DeviceContext);
  const { productState, productDispatch } = useContext<any>(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<ProductType>({} as ProductType);

  useEffect(() => {
    setLoading(true);
    const _id2 = location.pathname
      .split('?')
      .slice(-1)[0]
      .split('&')[0]
      .split('=')
      .slice(-1)[0];
    const _id: any = location.state ? location.state : null;
    if (_id) {
      setId(`${_id.id}`);
      fetchItem(`${_id.id}`);
    } else if (_id2) {
      setId(_id2);
      fetchItem(_id2);
    }
  }, []);

  useEffect(() => {
    if (productState.getSingleProduct) {
      const product = productState.getSingleProduct;
      if (product.id === Number(id)) {
        setItem(product);
        setLoading(false);
      }
    }
    if (productState.getSingleProductError) {
      history.push({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: productState.getSingleProductError!?.response!?.data!?.message
            ? productState.getSingleProductError!?.response!?.data!?.message
            : productState.getSingleProductError!?.message,
          code: productState.getSingleProductError.response
            ? productState.getSingleProductError.response.status
            : productState.getSingleProductError.status,
        },
      });
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
      setLoading(false);
    }
  }, [productState?.getSingleProduct]);

  useEffect(() => {
    if (item.id) setLoading(false);
  }, [item]);

  async function fetchItem(id: string) {
    if (productState?.getSingleProduct?.id === id) {
      const product = productState.getSingleProduct;
      setItem(product);
      return;
    }
    await getSingleProduct(id)(productDispatch);
  }
  const destroy = () => {
    setItem({} as ProductType);
    setId('');
  };
  return (
    <Layout>
      <div id="pageContainer" key={id}>
        {loading ? (
          <div className={classes.loading}>
            <Spinner type="crescent"></Spinner>
          </div>
        ) : size.width >= 768 ? (
          <div className={classes.desktop}>
            <IonGrid className={classes.desktopGrid}>
              <IonRow className={classes.holder}>
                <IonCol
                  size="12"
                  className={classes.leftbar}
                  style={{ padding: '0 15%' }}
                >
                  <div className={classes.product}>
                    <ItemDetailsCard
                      data={item}
                      productId={`${id}`}
                      onDestroy={destroy}
                    />
                  </div>
                </IonCol>
                <IonCol
                  size="4"
                  className={classes.rightbar}
                  style={{ display: 'none' }}
                >
                  <div className={classes.metricsHolder}>
                    <ProductMetrics data={[]} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : (
          <div className={classes.mobile}>
            <IonGrid className={classes.mobileGrid}>
              <IonRow className={classes.product}>
                <IonCol size="12">
                  <div className={classes.productHolder}>
                    <ItemDetailsCard
                      data={item}
                      productId={`${id}`}
                      onDestroy={destroy}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow
                className={classes.metricsCard}
                style={{ display: 'none' }}
              >
                <IonCol size="12">
                  <div className={classes.metricsHolder}>
                    <ProductMetrics data={[]} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ProductSeller;
