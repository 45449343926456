import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { useLocation } from 'react-router';
import { DeviceContext } from '../../deviceType';
import ErrSVG from '../../components/error';

// error messages

const messages = [
  {
    code: 0,
    message:
      'This action could not be completed. Check your internet connection and try again.',
  },
  {
    code: 429,
    message: 'Too many requests. Try again later',
  },
  {
    code: 400,
    message: '	There was a problem with your request. Please try again later',
  },
  {
    code: 401,
    message:
      '	The request was a legal request, but the server is refusing to respond to it. Please try again later',
  },
  {
    code: 403,
    message: 'The request is forbidden. Please try again later',
  },
  {
    code: 404,
    message:
      'The page you are looking for does not exist. Click the button below to go back.',
  },
  {
    code: 409,
    message:
      'The request could not be completed because of a conflict in the request.Please try again later',
  },
  {
    code: 500,
    message: 'The request could not be completed. Try again later',
  },
];

const Error = () => {
  const route = useLocation();
  const size = useContext(DeviceContext);
  const [error, setError] = useState<any>({ err: '', code: 0, from: '' });
  const [m, setM] = useState('');
  useEffect(() => {
    if (route.state instanceof Object) {
      setError({ ...route.state });
    } else {
      return;
    }
  }, [route.state]);
  useEffect(() => {
    const e = messages.find((item) => {
      return item.code === error.code;
    });
    if(e!?.message){
      setM(e!.message);
    }
  }, [error]);
  return (
    <div className={classes.container}>
      <IonGrid>
        {size.width > 768 ? (
          <IonRow className={classes.desktop}>
            <IonCol size="7" className={classes.one}>
              <div className={classes.logo}>Bazaar Cheap </div>
              <div className={classes.svg}>
                <ErrSVG />
              </div>
            </IonCol>
            <IonCol size="5" className={classes.two}>
              <div className={classes.top}>
                <IonButton color="secondary" routerLink="/login">
                  Login
                </IonButton>
              </div>
              <div className={classes.code}>{error.code}</div>
              <div className={classes.info}>
                <h3>{error!?.err}</h3>
                <p>{m}</p>
              </div>
              {error.code === 404 ? <div className={classes.btn}>
                <IonButton color="secondary" routerLink="/">
                  Go Home 
                </IonButton>
              </div>
              :<div className={classes.btn}>
                <IonButton color="secondary" routerLink={error!?.from}>
                  Try Again
                </IonButton>
              </div>}
            </IonCol>
          </IonRow>
        ) : (
          <IonRow className={classes.mobile}>
            <IonCol size="12">
              <div className={classes.head}>
                <div className={classes.logo}>Bazaar Cheap </div>
                <IonButton color="secondary" routerLink="/login">
                  Login
                </IonButton>
              </div>
              <div className={classes.svg}>
                <ErrSVG />
              </div>
              <div className={classes.code}>{error.code}</div>
              <div className={classes.info}>
                <h3>{error!?.err}</h3>
                <p>{m}</p>
              </div>
              {error.code === 404 ? <div className={classes.btn}>
                <IonButton color="secondary" routerLink="/">
                  Go Home 
                </IonButton>
              </div>
              :<div className={classes.btn}>
                <IonButton color="secondary" routerLink={error!?.from}>
                  Try Again
                </IonButton>
              </div>}
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </div>
  );
};

export default Error;
