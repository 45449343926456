import {
  IonCol,
  IonRow,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid,
} from '@ionic/react';
import StoreMenu from '../../../storeMenu/storeMenu';
import { Routes } from '../../../../utils/user/routes';
import classes from './styles.module.scss';
import { useMemo, useState } from 'react';
import Menu from '../../../storeMenu/menu';
import { useHistory, useLocation } from 'react-router';
import SearchBar from '../searchBar/searchBar';
import { DecodedToken} from '../../../../utils/getClientInfo';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';
import { authService } from '../../../../utils/authService';

const Desktop = ({
  user,
  backgroundColor,
}: {
  backgroundColor?: 'primary' | 'white';
  user: any;
}) => {
  const userService = useMemo(
    () => authService.getCurrentUser() as DecodedToken,
    []
  );
  const _isloggedIn = userService.id;
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState<{ isOpen: boolean; event: Event | null }>({
    isOpen: false,
    event: null,
  });

  const openCntx = (e: any) => {
    setOpen({ isOpen: !open.isOpen, event: e });
    console.log(e);
  };

  return (
    <div className={classes.desktop}>
      {_isloggedIn >= 1 && (
        <Menu
          open={open.isOpen}
          close={openCntx}
          userDetails={user}
          openEvent={open.event!}
        />
      )}
      <IonGrid
        style={{
          background: backgroundColor
            ? `var(--ion-color-primary-contrast)`
            : 'var(--ion-color-primary)',
        }}
        className={classes.holder}
      >
        <IonRow>
          <IonCol
            size={history.location.pathname === '/' ? '4' : '2'}
            className={classes.logo}
          >
            <div
              className={classes.holder}
              onClick={() => {
                history.push('');
              }}
            >
              <IonIcon src={logo}></IonIcon>
              <span
                className={classes.span1}
                style={{
                  color:
                    history.location.pathname === '/'
                      ? 'var(--ion-color-primary)'
                      : 'white',
                }}
              >
                Bazaar
              </span>{' '}
              <span className={classes.span2}>Cheap</span>
            </div>
          </IonCol>
          <IonCol
            size={history.location.pathname === '/' ? '5.8' : '4.5'}
            className={classes.navlinks}
          >
            <IonRow>
              {Routes.map((item, index) => {
                if (item.disabled) return null;
                if (item.isloggedIn && !userService.id) return null;
                return (
                  <IonCol
                    onClick={() => {
                      history.push(item.route);
                    }}
                    className={classes.navItem}
                    key={index}
                    size='3'
                    style={{
                      color:
                        location.pathname === item.route
                          ? 'var(--ion-color-secondary)'
                          : backgroundColor === 'white'
                          ? `var(--ion-color-primary)`
                          : 'var(--ion-color-primary-contrast)',
                    }}
                  >
                    {item.name}
                  </IonCol>
                );
              })}
            </IonRow>
          </IonCol>

          {/* Search disabled for first version  */}
          <IonCol
            size="0" // change this in time
            className={classes.search}
            style={{ display: 'none' }}
          >
            <div className={classes.holder}>
              {location.pathname !== '/' && location.pathname !== '/search' && (
                <SearchBar />
              )}
            </div>
          </IonCol>

          <IonCol size="2" className={classes.storeMenu}>
            {_isloggedIn ? (
              <div className={classes.chip}>
                <StoreMenu
                  userDetails={user}
                  open={open.isOpen}
                  openCntx={openCntx}
                />
              </div>
            ) : (
              <div className={classes.btnGrp}>
                <IonButtons>
                  <IonButton
                    routerLink="/signup"
                    color="secondary"
                    fill="clear"
                    className={classes.one}
                  >
                    Sign Up
                  </IonButton>
                  <IonButton
                    routerLink="/login"
                    color="secondary"
                    fill="solid"
                    className={classes.two}
                  >
                    Log In
                  </IonButton>
                </IonButtons>
              </div>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};
export default Desktop;
