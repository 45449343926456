import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
  IonRippleEffect,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from '@ionic/react';
import { addSharp } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { DeviceContext } from '../../deviceType';
import classes from './styles.module.scss';
import ugx from '../../Assets/UGX.svg';
import RichTextInput from '../rich text input';
import ImageCarouselInput from '../imageCarouselInput';
import { ProductType } from '../../types/product';

const ItemInputForm = ({
  formData = {} as ProductType,
  handleChange,
  types,
  oldImages,
}: {
  types: any;
  oldImages?: string[];
  handleChange: (value: string, name: string) => void;
  formData: ProductType;
}) => {
  const size = useContext(DeviceContext);
  const [openAddTag, setOpenAddTag] = useState<boolean>(false);
  const [characterCount, setCharacterCount] = useState<any>({
    title: { max: 84, remainder: 84 },
    description: { max: 246, remainder: 246 },
    details: { max: 1800, remainder: 1800 },
  });

  function counter(title: string, maxValue: number, currentCount: number) {
    // the counter feature on some inputs was causing a rerender on update and reloading
    // the formData state to default which hindered update of the state making use of the fields impossible
    // Im unsure why it resets the state to default so ive simply removed the counter feature and may be revisited at a later date
    const value = maxValue - currentCount;
    setCharacterCount({
      ...characterCount,
      [title]: { max: maxValue, remainder: value },
    });
  }

  return (
    <div className={classes.item}>
      {size.width > 769 ? (
        <IonGrid className={classes.grid_desktop}>
          <IonRow className={classes.infoInput}>
            <IonCol size="5" className={classes.headImg}>
              <div className={classes.imageInput}>
                <ImageCarouselInput
                  handleInput={handleChange}
                  editable={true}
                  images={oldImages}
                />
              </div>
            </IonCol>
            <IonCol size="7" className={classes.typography}>
              <div className={classes.title}>
                <IonLabel className={classes.label}>Title</IonLabel>
                <div className={classes.card + ` ${classes.titleCard}`}>
                  <IonInput
                    type="text"
                    name="name"
                    placeholder="Enter Title (Max Length 84)"
                    value={formData.name!}
                    onIonChange={(e) => {
                      if (e.detail.value) {
                        handleChange(e.detail.value!, 'name');
                        // counter(
                        //   'title',
                        //   characterCount.title.max,
                        //   e.detail.value!.length
                        // );
                      }
                    }}
                    maxlength={characterCount.title.max}
                  ></IonInput>
                  {/* <p className={classes.counters}>
                    {characterCount.title.remainder}/{characterCount.title.max}
                  </p> */}
                </div>
              </div>
              <div className={classes.info}>
                <IonRow>
                  <IonCol size="5.8" className={classes.price}>
                    <IonLabel className={classes.label}>Price</IonLabel>
                    <div className={classes.card + ` ${classes.priceCard}`}>
                      <div className={classes.currency}>UGX</div>
                      <IonInput
                        type="number"
                        placeholder="Price"
                        name="price"
                        value={formData?.price!}
                        maxlength={10}
                        onIonChange={(e: any) => {
                          e.stopPropagation();
                          handleChange(e.detail.value!, 'price');
                        }}
                      ></IonInput>
                    </div>
                  </IonCol>
                  <IonCol size="5.8" className={classes.type}>
                    <IonLabel className={classes.label}>Type</IonLabel>
                    <div className={classes.card + ` ${classes.select}`}>
                      <IonSelect
                        color="primary"
                        placeholder={'Type'}
                        value={formData.type!}
                        onIonChange={(e: any) => {
                          e.stopPropagation();
                          handleChange(e.detail.value!, 'type');
                        }}
                      >
                        {types?.map((item: string, index: number) => (
                          <IonSelectOption key={index} value={item}>
                            <p>{item}</p>
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
              <div className={classes.description}>
                <IonLabel className={classes.label}>Description</IonLabel>
                <div className={classes.card + ` ${classes.desCard}`}>
                  <IonTextarea
                    placeholder="Enter Product Description"
                    value={formData.description!}
                    maxlength={characterCount.description.max}
                    autoGrow={false}
                    rows={20}
                    onIonChange={(e: any) => {
                      handleChange(e.target.value!, 'description');
                      // counter(
                      //   'description',
                      //   characterCount.description.max,
                      //   e.target.value!.length
                      // );
                    }}
                  ></IonTextarea>
                  {/* <p className={classes.counters}>
                    {characterCount.description.remainder}/
                    {characterCount.description.max}
                  </p> */}
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.productDetails}>
            <IonCol size="12">
              <div className={classes.label}>
                Product Details{' '}
                <span className={classes.optional}>(Optional)</span>
              </div>
              <div className={classes.rich_text + ` ${classes.card}`}>
                <RichTextInput
                  handleChange={handleChange}
                  value={formData.details!}
                />
              </div>
            </IonCol>
          </IonRow>
          {/* Product Tags Disabled */}
          <IonRow className={classes.productTags} style={{ display: 'none' }}>
            <IonCol size="12">
              <div className={classes.label}>
                <p>Product Tags</p>{' '}
                <div
                  className={
                    classes.add + ' ion-activatable ripple-parent circle'
                  }
                  onClick={() => setOpenAddTag(true)}
                >
                  <IonRippleEffect></IonRippleEffect>
                  <IonIcon icon={addSharp} color={'primary'}></IonIcon>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        <IonGrid className={classes.grid_mobile}>
          <IonRow className={classes.imageInput}>
            <IonCol size="12">
              <div className={classes.input} style={{ height: '50vh' }}>
                <ImageCarouselInput
                  handleInput={handleChange}
                  editable={true}
                  images={oldImages}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.title}>
            <IonCol size="12">
              <IonLabel className={classes.label}>Title</IonLabel>
              <div className={classes.card + ` ${classes.titleCard}`}>
                <IonInput
                  type="text"
                  placeholder="Enter Title (Max Length 84)"
                  value={formData.name!}
                  onIonChange={(e) => {
                    handleChange(e.detail.value!, 'name');
                    counter(
                      'title',
                      characterCount.title.max,
                      e.detail.value!.length
                    );
                  }}
                  maxlength={characterCount.title.max}
                ></IonInput>
                <p className={classes.counters}>
                  {characterCount.title.remainder}/{characterCount.title.max}
                </p>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.info}>
            <IonCol size="5.8">
              <IonLabel className={classes.label}>Price</IonLabel>
              <div className={classes.card + ` ${classes.priceCard}`}>
                <div className={classes.currency}>UGX</div>
                <IonInput
                  type="number"
                  placeholder="Price"
                  value={formData.price!}
                  maxlength={10}
                  onIonChange={(e) => {
                    handleChange(e.detail.value!, 'price');
                  }}
                ></IonInput>
              </div>
            </IonCol>
            <IonCol size="5.8">
              <IonLabel className={classes.label}>Type</IonLabel>
              <div className={classes.card + ` ${classes.select}`}>
                <IonSelect
                  color="primary"
                  placeholder={'Type'}
                  value={formData.type!}
                  onIonChange={(e) => {
                    handleChange(e.detail.value!, 'type');
                  }}
                >
                  {types?.map((item: string, index: number) => (
                    <IonSelectOption key={index} value={item}>
                      <p>{item}</p>
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.description}>
            <IonCol size="12">
              <IonLabel className={classes.label}>Description</IonLabel>
              <div className={classes.card + ` ${classes.desCard}`}>
                <textarea
                  placeholder="Enter Product Description"
                  value={formData.description!}
                  maxLength={characterCount.description.max}
                  onChange={(e) => {
                    handleChange(e.target.value!, 'description');
                    counter(
                      'description',
                      characterCount.description.max,
                      e.target.value!.length
                    );
                  }}
                ></textarea>
                <p className={classes.counters}>
                  {characterCount.description.remainder}/
                  {characterCount.description.max}
                </p>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.details}>
            <IonCol size="12">
              <div className={classes.label}>Product Details</div>
              <div className={classes.rich_text + ` ${classes.card}`}>
                <RichTextInput
                  handleChange={handleChange}
                  value={formData.details!}
                />
              </div>
            </IonCol>
          </IonRow>
          {/* Product Tags Disabled */}
          <IonRow className={classes.productTags} style={{ display: 'none' }}>
            <IonCol size="12">
              <div className={classes.label}>
                <p>Product Tags</p>{' '}
                <div
                  className={
                    classes.add + ' ion-activatable ripple-parent circle'
                  }
                  onClick={() => setOpenAddTag(true)}
                >
                  <IonRippleEffect></IonRippleEffect>
                  <IonIcon icon={addSharp} color={'primary'}></IonIcon>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </div>
  );
};

export default ItemInputForm;
