import { IonButton, IonSpinner } from '@ionic/react';
import classes from './styles.module.scss';

type BtnProps = {
	loading?: boolean;
	match?: boolean;
	name: string;
	onClick?: (event: any) => void;
};

const Btn = ({
	loading = false,
	match = true,
	name = 'Submit',
	onClick = () => {},
}: BtnProps) => {
	return (
		<IonButton
			type='submit'
			onClick={onClick}
			color='secondary'
			expand='block'
			fill='solid'
			className={classes.button}
			disabled={!match || loading}
		>
			{loading && <IonSpinner slot='end'></IonSpinner>}
			{name}
		</IonButton>
	);
};

export default Btn;
