export const getCurrentStore = () => {
  const storeid = localStorage.getItem('currentStore');
  return storeid;
};

export const setCurrentStore = (store: {
  id: number;
  name: string;
  default?: boolean;
}) => {
  if (store) {
    window.localStorage.setItem('currentStore', store.id.toString());
    window.dispatchEvent(new Event('storage'));
    return true;
  } else return false;
};
