import { IonButton } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import ItemInputForm from '../../../components/createItemForm/item';
import {
  getProductTypes,
  getSingleProduct,
  createProduct,
  updateProduct,
} from '../../../context/actions/products';
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  GET_SINGLE_PRODUCT_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
} from '../../../context/actions/types';
import { GlobalContext } from '../../../context/provider';
import { DeviceContext } from '../../../deviceType';
import { useFileUpload } from '../../../hooks/files';
import { FolderEnum } from '../../../utils/fileUpload';
import classes from './styles.module.scss';
import Layout from '../../../components/Layout/dashboard';
import Spinner from '../../../components/spinner';
import { ProductType } from '../../../types/product';

const CreateEditProduct = () => {
  const size = useContext(DeviceContext);
  const { productTypes, productTypesDispatch, productState, productDispatch } =
    useContext<any>(GlobalContext);
  const [error, setError] = useState<{ state: boolean; msg: string | null }>({
    state: false,
    msg: null,
  });
  const [product_types, setproduct_types] = useState<string[]>([]);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [item, setItem] = useState<ProductType>({} as ProductType);
  const { uploadFile } = useFileUpload();
  const [currentform, setCurrentForm] = useState<{
    name: string;
    form: JSX.Element;
  } | null>(null);

  const forms = [
    {
      name: 'Create Product',
      form: (
        <ItemInputForm
          types={product_types}
          handleChange={handleChange}
          formData={item}
        />
      ),
    },
    {
      name: 'Edit Product',
      form: (
        <ItemInputForm
          types={product_types}
          handleChange={handleChange}
          formData={item}
          oldImages={item.images}
        />
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    async function fetchProductTypes() {
      if (productTypes.productTypes) {
        return setproduct_types(productTypes.productTypes.data);
      }
      await getProductTypes()(productTypesDispatch);
    }
    fetchProductTypes();
  }, []);

  useEffect(() => {
    if (currentform) {
      setLoading(false);
    }

  }, [currentform]);

  useEffect(() => {
    // debugger
    const prod = item;
    const path = location.pathname;
    if (path.includes('edit-product') && !currentform) {
      if (item!.id) {
        setCurrentForm(forms[1]);
      }
    }
  }, [item]);

  useEffect(() => {
    if (product_types.length) {
      const path = location.pathname;
      if (path.includes('edit-product')) {
        // setCurrentForm(forms[1]);
        fetchProduct();
      } else if (path.includes('create-product')) {
        setCurrentForm(forms[0]);
      }
    }
  }, [product_types]);

  useEffect(() => {
    if (productTypes.productTypes) {
      setproduct_types(productTypes.productTypes.data);
    }
    if (productTypes.productTypesError) {
      history.push({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: productTypes.ProductTypesError!?.response!?.data!?.message
            ? productTypes.ProductTypesError!?.response!?.data!?.message
            : productTypes.ProductTypesError!?.message,
          code: productTypes.productTypesError.response
            ? productTypes.productTypesError.response.status
            : productTypes.productTypesError.status,
        },
      });
      productTypesDispatch({});
    }
  }, [productTypes]);

  useEffect(() => {
    if (productState.createProduct) {
      const product = productState.createProduct;
      destroy();
      productDispatch({ type: CREATE_PRODUCT, payload: null });
      history.push({
        pathname: `/dashboard/items/detail?q=${
          product.id
        }&i=${product.name.slice(0, 30)}`,
        state: { id: product.id },
      });
    }
    if (productState.createProductError) {
      setError({
        state: true,
        msg: productState.createProductError,
      });
      setLoading(false);
      productDispatch({ type: CREATE_PRODUCT_ERROR, payload: null });
    }

    if (productState.getSingleProduct && currentform?.name === 'Edit Product') {
      const product = productState.getSingleProduct;
      setItem(product);
    }
    if (
      productState.getSingleProductError &&
      currentform?.name === 'Edit Product'
    ) {
      history.replace({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: productState.getSingleProductError!?.response!?.data!?.message
            ? productState.getSingleProductError!?.response!?.data!?.message
            : productState.getSingleProductError!?.message,
          code: productState.getSingleProductError.response
            ? productState.getSingleProductError.response.status
            : productState.getSingleProductError.status,
        },
      });
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
    }
    if (productState.updateProduct) {
      const product = productState.updateProduct.data;
      // clear all state variables
      destroy();
      history.push({
        pathname: `/dashboard/items/detail?q=${
          product.id
        }&i=${product.name.slice(0, 30)}`,
        state: { id: product.id },
      });
      productDispatch({ type: UPDATE_PRODUCT, payload: null });
    }
    if (productState.updateProductError) {
      setError({
        state: true,
        msg: productState.updateProductError,
      });
      setLoading(false);
      productDispatch({ type: UPDATE_PRODUCT_ERROR, payload: null });
    }
  }, [productState, currentform]);

  async function saveItem() {
    const itemData = {
      ...item,
      active: true,
    };
    setLoading(true);
    const path = location.pathname;
    if (itemData.images?.length) {
      const uploadedImages = await handleImageUpload(itemData.images as File[]);
      const imgs: string[] = [
        ...itemData.images.filter((e) => {
          return typeof e === 'string';
        }),
        ...uploadedImages,
      ];
      itemData.images = imgs;
    }
    const { id, ...rest } = itemData;
    if (path.includes('edit-product')) {
      return await updateProduct({ id: id!.toString(), data: rest })(
        productDispatch
      );
    } else if (path.includes('create-product')) {
      return await createProduct(itemData)(productDispatch);
    }
  }
  const handleImageUpload = async (images: File[]) => {
    const _images = images.filter((e) => e instanceof File);
    const uploadImages = await uploadFile({
      files: _images,
      folder: FolderEnum.Products,
    });
    if (uploadImages?.error) {
      setError({
        state: true,
        msg: uploadImages.error,
      });
      setLoading(false);
      return;
    }
    setUploadedImages(uploadImages as string[]);
    return uploadImages;
  };
  async function saveDraft() {
    const draft = {
      ...item,
      active: false,
    };
    setLoading(true);
    const uploadedImages = await handleImageUpload(draft.images as File[]);
    draft.images = uploadedImages || [];

    await createProduct(draft)(productDispatch);
  }
  function handleChange(value: string, label: string) {

    setItem((prev) => {
      return {
        ...prev,
        [label]: value,
      };
    });
  }
  function cancelEdit() {
    const product = item;
    productDispatch({ type: CREATE_PRODUCT, payload: null });
    history.push({
      pathname: `/dashboard/items/detail?q=${product.id}&i=${product.name.slice(
        0,
        30
      )}`,
      state: { id: product.id },
    });
    setItem({} as ProductType);
  }
  async function fetchProduct() {
    const productID: any = location.state;
    if (!productID.hasOwnProperty('id')) {
      return history.replace({
        pathname: '/err',
        state: {
          from: '/dashboard/store',
          err: 'Something Went Wrong!',
          code: 404,
        },
      });
    }
    if (
      productState.getSingleProduct?.hasOwnProperty('id') &&
      productState.getSingleProduct?.id === Number(productID!?.id)
    ) {
      return setItem(productState.getSingleProduct);
    }
    await getSingleProduct(productID.id)(productDispatch);
  }
  const validate = (): boolean => {
    if (
      item.name?.trim().length > 0 &&
      item.price?.toString().length > 0 &&
      item.description!?.length > 0 &&
      item.type?.length > 0
    ) {
      return true;
    }
    return false;
  };
  const destroy = async () => {
    setCurrentForm(null);
    setItem({} as ProductType);
    setUploadedImages([]);
    setLoading(false);
  };

  return (
    <Layout>
      <div id="pageContainer">
        {loading ? (
          <div className={classes.loading}>
            <Spinner type="circles" />
          </div>
        ) : (
          <div className={classes.formContainer}>
            <div className={classes.formLabel}>{currentform?.name}</div>
            <div className={classes.formBody}>
              <div
                className={classes.segmentContainer}
                style={{
                  margin: '0 auto',
                  width: size.width <= 768 ? '94%' : '80%',
                }}
              >
                {currentform?.form}
              </div>
              <div
                className={classes.save}
                style={{ margin: size.width <= 768 ? '0 3%' : '0 10%' }}
              >
                {error.state && (
                  <div className={classes.errorHolder}>{error.msg}</div>
                )}
                <IonButton
                  color="dark"
                  fill="clear"
                  onClick={() => {
                    if (currentform?.name === 'Edit Product') {
                      return cancelEdit();
                    }
                    if (item!?.name.trim().length > 0) {
                      setError({ state: false, msg: null });
                      saveDraft();
                    } else {
                      setError({
                        state: true,
                        msg: 'Please fill atleast One Required Field',
                      });
                    }
                  }}
                >
                  {currentform?.name === 'Edit Product'
                    ? 'Cancel'
                    : 'Save Draft'}
                </IonButton>
                <IonButton
                  disabled={!validate()}
                  color="secondary"
                  fill="solid"
                  onClick={saveItem}
                >
                  Save
                </IonButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CreateEditProduct;
