import {
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { DeviceContext } from '../../../../deviceType';
import classes from './styles.module.scss';
import { menuController } from '@ionic/core';
import StoreMenu from '../../../storeMenu/storeMenu';
import Menu from '../../../storeMenu/menu';
import { User } from '../../../../types/user';
import { hideMenuPaths } from '../../../../utils';

const SellerNav = (props: { userDetails: User }) => {
  const size = useContext(DeviceContext);
  const path = useLocation();
  const [open, setOpen] = useState<any>();
  const [openStoreMenu, setOpenStoreMenu] = useState<{
    isOpen: boolean;
    event: Event | null;
  }>({
    isOpen: false,
    event: null,
  });
  useEffect(() => {
    if (
      open &&
      hideMenuPaths.includes(path.pathname)
    ) {
      menuController.close('menu');
    }
  }, [path.pathname, open]);
  const openCntx = (e: any) => {
    setOpenStoreMenu({ isOpen: !openStoreMenu.isOpen, event: e });
  };
  return (
    <IonHeader className={classes.Container}>
      <Menu
        open={openStoreMenu.isOpen}
        close={openCntx}
        userDetails={props.userDetails}
        openEvent={openStoreMenu.event!}
      />
      {size.width > 769 ? (
        <IonToolbar color="primary" className={classes.holder} mode="md">
          <IonGrid>
            <IonRow>
              <IonCol size="9" className={classes.logo}>
                <IonTitle>Bazaar Cheap</IonTitle>
              </IonCol>
              <IonCol size="1.3" className={classes.storeMenu}>
                <div className={classes.chip}>
                  <StoreMenu
                    userDetails={props.userDetails}
                    open={openStoreMenu.isOpen}
                    openCntx={openCntx}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      ) : (
        <IonToolbar color="primary" className={classes.holder} mode="md">
          <IonGrid>
            <IonRow>
              <IonCol size="1.5" className={classes.menu}>
                <IonButtons>
                  <IonMenuButton
                    onClick={() => {
                      setOpen(menuController.isOpen('menu'));
                    }}
                  />
                </IonButtons>
              </IonCol>
              <IonCol size="7.5" className={classes.logo}>
                <IonTitle>Bazaar Cheap</IonTitle>
              </IonCol>
              <IonCol size="2" className={classes.storeMenu}>
                <div className={classes.chip}>
                  <StoreMenu
                    userDetails={props.userDetails}
                    open={openStoreMenu.isOpen}
                    openCntx={openCntx}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      )}
    </IonHeader>
  );
};

export default SellerNav;
