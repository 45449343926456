import { IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Layout from '../../../components/Layout/user/Layout';
import { searchProducts } from '../../../context/actions/products';
import { getStore } from '../../../context/actions/store';
import {
  GET_STORE_ERROR,
  SEARCH_PRODUCTS,
} from '../../../context/actions/types';
import { GlobalContext } from '../../../context/provider';
import classes from './styles.module.scss';
import StoreProducts from './store-products';
import StorePageHead from './topBar';
import { ProductType } from '../../../types/product';
import Spinner from '../../../components/spinner';

const SingleStore = () => {
  const { storeState, storeDispatch, productState, productDispatch } =
    useContext<any>(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const [store, setStore] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [products, setProducts] = useState<ProductType[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const params = useParams<any>();

  useEffect(() => {
    let slug = location.pathname.split('/')[1];
    if (slug) {
      fetchStore(slug);
    }
  }, []);

  useEffect(() => {
    if (storeState?.store) {
      setStore(storeState.store);
      setProducts(storeState.store?.products);
      setLoading(false);
    }
    if (storeState.storeError) {
      history.push({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: storeState.storeError!?.response!?.data!?.message
            ? storeState.storeError!?.response!?.data!?.message
            : storeState.storeError!?.message,
          code: storeState.storeError.request.status
            ? storeState.storeError.request.status
            : storeState.storeError.status,
        },
      });
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState?.store, storeState.storeError]);

  async function fetchStore(e: any) {
    setLoading(true);
    if (storeState?.store && storeState.store.slug === params.store) {
      setStore(storeState.store);
      setProducts(storeState.store?.products);
      setLoading(false);
      return;
    }
    await getStore(e)(storeDispatch);
  }

  const handleSearch = async () => {
    setSearchLoading(true);
    await searchProducts({
      text: searchText,
      storeId: store.id,
    })(productDispatch);
  };

  useEffect(() => {
    if (productState.searchProducts?.data) {
      setSearchLoading(false);
      setProducts(productState.searchProducts.data);
    }
    productDispatch({ type: SEARCH_PRODUCTS, payload: null });
  }, [productState.searchProducts?.data]);

  return (
    <Layout>
      {loading ? (
        <Spinner />
      ) : (
        <div className={classes.mainHolder}>
          <IonGrid className={classes.storeGrid}>
            <IonRow className={classes.topBar}>
              <IonCol size="12">
                <StorePageHead
                  store={store}
                  setSearchText={setSearchText}
                  handleSearch={handleSearch}
                  searchText={searchText}
                />
              </IonCol>
              <IonCol size="12">
                {searchLoading ? (
                  <Spinner />
                ) : (
                  <StoreProducts
                    store={store}
                    products={products}
                    getAll={() => setProducts(store.products)}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
    </Layout>
  );
};

export default SingleStore;
