import React, { createContext, useReducer } from 'react';
import userReducer, { userInitialState, userState } from './reducers/user';
import storeReducer, { storeInitialState, storeState } from './reducers/store';
import roleReducer, { roleInitialState } from './reducers/role';
import productReducer, {
  productInitialState,
  productState,
} from './reducers/product';
import storeTypesReducer, {
  storeTypeInitialState,
  storeTypesState,
} from './reducers/store_type';
import productTypesReducer, {
  productTypeInitialState,
} from './reducers/product_types';
import topCategoriesReducer, {
  topCategoriesInitialState,
} from './reducers/top_categories';
import contactUsReducer, { contactUsInitialState } from './reducers/contact_us';

//global state type for type safety
export interface GlobalStore {
  userState?: userState;
  userDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  productTypes?: any;
  productTypesDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  storeState?: storeState;
  storeTypes?: storeTypesState;
  roleState?: any;
  roleDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  storeTypesDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  storeDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  productState?: productState;
  productDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  topCategoriesState?: any;
  topCategoriesDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  contactUsState?: any;
  contactUsDispatch?: React.Dispatch<{
    type: string;
    payload: any;
  }>;
}

// Initial state
export const GlobalContext = createContext<GlobalStore>({});

export const GlobalProvider = ({ children }: { children: any }) => {
  const [userState, userDispatch] = useReducer(userReducer, userInitialState);
  const [roleState, roleDispatch] = useReducer(roleReducer, roleInitialState);
  const [productState, productDispatch] = useReducer(
    productReducer,
    productInitialState
  );
  const [storeTypes, storeTypesDispatch] = useReducer(
    storeTypesReducer,
    storeTypeInitialState
  );
  const [productTypes, productTypesDispatch] = useReducer(
    productTypesReducer,
    productTypeInitialState
  );
  const [storeState, storeDispatch] = useReducer(
    storeReducer,
    storeInitialState
  );
  const [topCategoriesState, topCategoriesDispatch] = useReducer(
    topCategoriesReducer,
    topCategoriesInitialState
  );

  const [contactUsState, contactUsDispatch] = useReducer(
    contactUsReducer,
    contactUsInitialState
  );

  return (
    <GlobalContext.Provider
      value={{
        userState,
        userDispatch,
        productTypes,
        productTypesDispatch,
        storeState,
        storeTypes,
        roleState,
        roleDispatch,
        storeTypesDispatch,
        storeDispatch,
        productState,
        productDispatch,
        topCategoriesState,
        topCategoriesDispatch,
        contactUsState,
        contactUsDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
