import axios from 'axios';
import { Dispatch } from 'react';
import {
  SignupUser,
  LogInUser,
  ForgotPasswordObj,
  LoginAdmin,
} from '../../types/user';
import { axiosInstance } from '../../utils/axios';
import {
  GETUSER,
  GETUSERERROR,
  LOGIN,
  LOGIN_ERROR,
  SIGNUP,
  SIGNUP_ERROR,
  FORGOTPASSWORD,
  FORGOTPASSWORD_ERROR,
  VERIFYPASSWORD,
  VERIFYPASSWORD_ERROR,
  RESETPASSWORD,
  RESETPASSWORD_ERROR,
  ADMINLOGIN,
  ADMINLOGIN_ERROR,
  BECOMESELLER,
  BECOMESELLER_ERROR,
  UPDATE_USER,
  UPDATE_USER_ERROR,
} from './types';

export const signup =
  (payload: SignupUser) =>
  async (dispatch: Dispatch<{ type: string; payload: SignupUser }>) => {
    try {
      const res = await axios.post('/v1/users/signup', payload);
      dispatch({
        type: SIGNUP,
        payload: res.data?.data,
      });
    } catch (e: any) {
      dispatch({
        type: SIGNUP_ERROR,
        payload: e,
      });
    }
  };
  export const updateUser =
  (payload: {id:number | string, data:any}) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.put(`/v1/users/${payload.id}`, payload.data);
      dispatch({
        type: UPDATE_USER,
        payload: res.data?.data,
      });
    } catch (e: any) {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: e,
      });
    }
  };

export const login =
  (payload: LogInUser) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axios.post('/v1/users/login', payload);
      const token = res.data?.data;
      localStorage.setItem('token', token);

      dispatch({
        type: LOGIN,
        payload: res.status,
      });
    } catch (e: any) {
      dispatch({
        type: LOGIN_ERROR,
        payload: e,
      });
    }
  };

export const getUserInfo =
  (id: number) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/users/${id}`);
      dispatch({
        type: GETUSER,
        payload: res.data?.data,
      });
    } catch (e: any) {
      dispatch({ type: GETUSERERROR, payload: e });
    }
  };

export const forgotPassword =
  (payload: ForgotPasswordObj) =>
  async (dispatch: Dispatch<{ type: string; payload: ForgotPasswordObj }>) => {
    try {
      const res = await axiosInstance.post(
        '/v1/users/forgot-password',
        payload
      );
      dispatch({ type: FORGOTPASSWORD, payload: res.data?.data });
    } catch (e: any) {
      dispatch({ type: FORGOTPASSWORD_ERROR, payload: e });
    }
  };

export const verifyPassword =
  (payload: { email: string; code: string }) =>
  async (dispatch: Dispatch<{ type: string; payload: string }>) => {
    try {
      const res = await axiosInstance.post(
        'v1/users/forgot-password-verify',
        payload
      );
      dispatch({ type: VERIFYPASSWORD, payload: res.data?.data });
    } catch (e: any) {
      dispatch({ type: VERIFYPASSWORD_ERROR, payload: e });
    }
  };

export const resetPassword =
  (payload: { password: string; email: string; code: string }) =>
  async (dispatch: Dispatch<{ type: string; payload: string }>) => {
    try {
      const res = await axiosInstance.post('v1/users/reset-password', payload);
      dispatch({ type: RESETPASSWORD, payload: res.data?.data });
    } catch (e: any) {
      dispatch({ type: RESETPASSWORD_ERROR, payload: e });
    }
  };

export const AdminLogin =
  (payload: LoginAdmin) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.post('v1/users/login/admin', payload);
      const token = res.data?.data;
      localStorage.setItem('token', token);
      dispatch({ type: ADMINLOGIN, payload: true });
    } catch (e: any) {
      dispatch({ type: ADMINLOGIN_ERROR, payload: e });
    }
  };

export const BecomeSeller =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.post('v1/users/become-a-seller');
      const token = res.data?.data;
      localStorage.setItem('token', token);
      dispatch({ type: BECOMESELLER, payload: token });
    } catch (e: any) {
      dispatch({ type: BECOMESELLER_ERROR, payload: e });
    }
  };
