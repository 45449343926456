import React, { useContext, useEffect, useState } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  BarChart,
} from 'recharts';
import classes from './styles.module.scss';
import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonRippleEffect,
} from '@ionic/react';
import { DeviceContext } from '../../deviceType';
import { ProductType } from '../../types/product';
import { getDateInfo } from '../../utils/dates';
import { calendar } from 'ionicons/icons';
import { startOfToday, format, subDays } from 'date-fns';

const BChart = ({
  data,
}: {
  data: { id: number | string; product: ProductType; createdAt: string }[];
}) => {
  const size = useContext(DeviceContext);
  const [daily, setDaily] = useState<{ day: string; list: number[] }[]>([]);
  const [_data, setData] = useState<{ name: string; value: number }[]>([]);

  useEffect(() => {
    createDateRange();
  }, [data]);

  useEffect(() => {
    const extracted_list = daily.map((e) => {
      return { name: e.day, value: e.list.length };
    });
    setData(extracted_list);
  }, [daily]);

  const createDateRange = () => {
    const today = startOfToday();
    const viewsByDate: any = Array.from({ length: 7 }, (_, i) =>
      getDateInfo(subDays(today, i).toISOString())
    )
      .slice(0, 7).reverse()
      .reduce(
        (prev, curr) => ({
          ...prev,
          [`${curr.day}, ${curr.date} ${curr.month} ${curr.year}`]: 0,
        }),
        {}
      );

    for (const item of data) {
      const dateInfo = getDateInfo(item.createdAt);
      const date = `${dateInfo.day}, ${dateInfo.date} ${dateInfo.month} ${dateInfo.year}`;
      if (viewsByDate.hasOwnProperty(date)) {
        viewsByDate[date]++;
      }
    }
    const list = Object.keys(viewsByDate).map((key) => {
      return { name: key, value: viewsByDate[key] };
    });
   setData(list);
  };

  return (
    <div className={classes.main}>
      <div className={classes.options}></div>
      <div className={classes.bgraph}>
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <BarChart data={_data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={'name'} />
            <YAxis interval={1} />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey={'value'} fill="#A94064" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BChart;
