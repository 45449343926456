import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { IonCol, IonGrid, IonRippleEffect, IonRow } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { getTotalSearchCount } from '../../utils/products';
import { getStore } from '../../context/actions/store';
import { GET_STORE_ERROR } from '../../context/actions/types';
import { GlobalContext } from '../../context/provider';
import { ProductType } from '../../types/product';
import CurrentStoreCard from '../currentstorecard';
import { getCurrentStore } from '../../utils/currentStore';

const StoreBar = () => {
  const history = useHistory();
  const [searchCount, setSearchCount] = useState<number>(0);
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const location = useLocation();
  const [store, setStore] = useState<{
    id: number;
    logo: null | string;
    name: string;
    products: any[];
    filteredProducts: any[];
  }>({
    id: 0,
    logo: null,
    name: '',
    filteredProducts: [],
    products: [],
  });

  const currentStore = getCurrentStore();
  useEffect(() => {
    if (currentStore) {
      fetchStore(currentStore);
    }
  }, [currentStore]);

  useEffect(() => {
    if (storeState.store) {
      extractStore(storeState.store);
    }
    if (storeState.storeError) {
      history.push({
        pathname: '/err',
        state: {
          err: storeState!?.storeError.response?.data
            ? storeState!?.storeError.response.data.message
            : storeState!?.storeError.message,
          code: storeState!?.storeError.response?.status
            ? storeState!?.storeError.response?.status
            : storeState!?.storeError.request?.status,
          from: location.pathname,
        },
      });
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState]);

  const fetchStore = async (_id: string) => {
    if (storeState.store) {
      extractStore(storeState.store);
      return;
    }
    await getStore(Number(_id))(storeDispatch);
  };

  const extractStore = (store: any) => {
    const { products, ...rest } = store;
    const filteredProducts = products?.sort((a: ProductType, b: ProductType) => {
        if (a.searchCount! < b.searchCount!) {
          return 1;
        }
        if (a.searchCount! > b.searchCount!) {
          return -1;
        }
        return 0;
      })
      .slice(0, 4);
    setSearchCount(getTotalSearchCount(products));
    setStore({ ...rest, filteredProducts, products });
  };

  return (
    <div className={classes.container}>
      <IonGrid className={classes.storebar_grid}>
        <IonRow className={classes.currentStore}>
          <IonCol size={'12'}>
            <CurrentStoreCard />
          </IonCol>
        </IonRow>
        <IonRow className={classes.store}>
          <IonCol size="12">
            <div className={classes.storeCard}>
              <IonGrid className={classes.s_grid}>
                <IonRow className={classes.head}>
                  <IonCol size="12">
                    <div className={classes.img}>
                      <img src={store.logo!} alt="Store Head"></img>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className={classes.title}>
                  <IonCol size="12">
                    <div className={classes.storeName}>{store.name}</div>
                  </IonCol>
                </IonRow>
                <IonRow className={classes.list}>
                  <IonCol size="12">
                    <div className={classes.listings}>
                      <div className={classes.label}>Total Products</div>
                      <div className={classes.tListings}>
                        {store.products?.length}
                      </div>
                    </div>
                    <div className={classes.views}>
                      <div className={classes.label}>Products Impressions</div>
                      <div className={classes.tViews}>{searchCount}</div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={classes.info}>
          <IonCol size="12">
            <div className={classes.recent}>
              <div className={classes.label}>
                <div className={classes.text}>Recent Products</div>{' '}
                <div
                  className={
                    classes.link + ' ion-activatable ripple-parent circle'
                  }
                  onClick={() => {
                    history.push({ pathname: 'dashboard/store' });
                  }}
                >
                  <IonRippleEffect></IonRippleEffect>
                  See All
                </div>
              </div>
              <div className={classes.list}>
                {store.filteredProducts?.map((item, index) => {
                  return (
                    <div
                      className={
                        classes.listItem +
                        ' ion-activatable ripple-parent circle'
                      }
                      onClick={() => {
                        history.push({
                          pathname: `/dashboard/items/detail?q=${
                            item.id
                          }&i=${item.name.slice(0, 30)}`,
                        });
                      }}
                      key={index}
                    >
                      <IonRippleEffect></IonRippleEffect>
                      <div className={classes.img}>
                        <img src={item?.images?.[0]} alt={'Product'} />
                      </div>
                      <div className={classes.text}>
                        <div className={classes.title}>{item.name}</div>{' '}
                        <div className={classes.price}>
                          UGX{' '}
                          {item.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default StoreBar;
