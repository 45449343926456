import { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import StoreBar from '../../../components/storeBar';
import { GlobalContext } from '../../../context/provider';
import { getStore } from '../../../context/actions/store';
import { GET_STORE_ERROR } from '../../../context/actions/types';
import { useLocation, useHistory } from 'react-router';
import PChart from '../../../components/pieChart';
import { ProductType } from '../../../types/product';
import BChart from '../../../components/barChart';
import MultipleTabCard from '../../../components/multipleTabCard';
import { storeSchema } from '../../../types/store';
import CurrentStoreCard from '../../../components/currentstorecard';
import { getCurrentStore } from '../../../utils/currentStore';
import Spinner from '../../../components/spinner';

const SellerHome = () => {
  const size = useContext(DeviceContext);
  const location = useLocation();
  const history = useHistory();

  // state variables
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [store, setStore] = useState<storeSchema>({
    products: [],
    productViews:[]
  } as storeSchema);
  const [productList, setProductList] = useState<ProductType[]>([]);
  const [categoryEngagements, setCategoryEngagements] = useState<
    { name: string; value: number }[]
  >([]);
  const [views, setViews] = useState<
    { id: number | string; product: any; createdAt: string }[]
  >([]);
  const [mostViewed, setMostViewed] = useState<ProductType[]>([]);
  const [mostSearched, setMostSearched] = useState<ProductType[]>([]);

  // state mutators
  useEffect(() => {
    // this listens for change in the localstorage
    setLoading(true);
    window.addEventListener('storage', () => {
      setLoading(true);
      const currentStore = getCurrentStore();
      fetchStore(currentStore as string);
    });
  }, []);

  useEffect(() => {
      const _products = store.products!.filter((i, index) => i.active);

      const mostsearched = _products
        .sort((a: ProductType, b: ProductType) => {
          if (a.searchCount! < b.searchCount!) {
            return 1;
          }
          if (a.searchCount! > b.searchCount!) {
            return -1;
          }
          return 0;
        })
        .slice(0, 5);
      const mostviewed = _products
        .sort((a: ProductType, b: ProductType) => {
          if (a.views!.length! < b.views!.length!) {
            return 1;
          }
          if (a.views!.length! > b.views!.length!) {
            return -1;
          }
          return 0;
        })
        .slice(0, 5);
      setViews(store.productViews!);
      setMostViewed(mostviewed);
      setMostSearched(mostsearched);
      setProductList(_products);
      const categoryEngagements = extractCategoryEngagements(
        store.productViews!
      );
      setCategoryEngagements(categoryEngagements);

  }, [store]);

  useEffect(() => {
    if (storeState.store) {
      const _store = storeState.store;
      setStore(_store);
    }
    if (storeState.storeError) {
      history.push({
        pathname: '/err',
        state: {
          err: storeState!?.storeError.response?.data
            ? storeState!?.storeError.response.data.message
            : storeState!?.storeError.message,
          code: storeState!?.storeError.response?.status
            ? storeState!?.storeError.response?.status
            : storeState!?.storeError.request?.status,
          from: location.pathname,
        },
      });
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
    setLoading(false);
  }, [storeState]);

  // functions
  const extractCategoryEngagements = (viewList: any[]) => {
    let list: { name: string; value: number }[] = [];
    for (let i = 0; i < viewList.length; i++) {
      if (list.some((e) => e.name === viewList[i].product.type)) {
        const index = list.findIndex(
          (e) => e.name === viewList[i].product.type
        );
        const group = {
          name: list[index].name,
          value: list[index].value + 1,
        };
        list[index] = group;
      } else {
        const group = {
          name: viewList[i].product.type,
          value: 1,
        };
        list = [...list, group];
      }
    }
    return list;
  };

  async function fetchStore(_id: string) {
    if (storeState.store) {
      const _store = storeState.store;
      setStore(_store);
      setLoading(false);
      return;
    }
    await getStore(Number(_id))(storeDispatch);
  }

  return (
    <Layout>
      {loading ? (
        <div className={classes.loading}>
          <Spinner type={'circular'} />
        </div>
      ) : (
        <div className={classes.holder}>
          {size.width > 768 && (
            <IonGrid className={classes.desktopGrid}>
              <IonRow className={classes.container}>
                <IonCol size="8.5" className={classes.leftBar}>
                  <div className={classes.homeGraphs}>
                    <div className={classes.bargraph}>
                      <div className={classes.label}>Product Views</div>
                      <div className={classes.chartHolder}>
                        <BChart data={views} />
                      </div>
                    </div>
                    <div className={classes.piechart}>
                      <div className={classes.label}>
                        Engagement By Category
                      </div>
                      <div className={classes.chartHolder}>
                        <PChart data={categoryEngagements} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.products}>
                    <MultipleTabCard
                      data={[
                        {
                          label: 'Most Searched',
                          default: true,
                          data: mostSearched,
                        },
                        { label: 'Most Viewed', data: mostViewed },
                      ]}
                    />
                  </div>
                </IonCol>
                <IonCol size="3.5" className={classes.rightBar}>
                  <StoreBar />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          {size.width <= 768 && (
            <IonGrid className={classes.mobileGrid}>
              <IonRow className={classes.currentStore}>
                <IonCol size="12">
                  <CurrentStoreCard />
                </IonCol>
              </IonRow>
              <IonRow className={classes.homeGraphs}>
                <IonCol size={'12'}>
                  <div className={classes.bargraph}>
                    <div className={classes.label}>Product Views</div>
                    <div className={classes.chartHolder}>
                      <BChart data={views} />
                    </div>
                  </div>
                  <div className={classes.piechart}>
                    <div className={classes.label}>Engagement By Category</div>
                    <div className={classes.chartHolder}>
                      <PChart data={categoryEngagements} />
                    </div>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className={classes.products}>
                <IonCol size="12">
                  <div className={classes.holder}>
                    <MultipleTabCard
                      data={[
                        {
                          label: 'Most Searched',
                          default: true,
                          data: mostSearched,
                        },
                        { label: 'Most Viewed', data: mostViewed },
                      ]}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </div>
      )}
    </Layout>
  );
};

export default SellerHome;
