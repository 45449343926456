import {useState, useContext, useEffect} from 'react';
import { useHistory} from 'react-router-dom';
import { IonRow, IonCol } from '@ionic/react';
import Btn from '../../../components/Btn';
import InputCard from '../components/inputCard';
import classes from './styles.module.scss';
import { AdminLogin } from '../../../context/actions/user';
import { GlobalContext } from '../../../context/provider';
import { ADMINLOGIN_ERROR } from '../../../context/actions/types';
import { eye } from 'ionicons/icons';
import AuthLayout from '../components/AuthLayout';
import { DeviceContext } from '../../../deviceType';


const AdministratorLogin = ()=>{
    const size = useContext<any>(DeviceContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<{ email: string; password: string }>({
        email: '',
        password: ''
    });
    const [error, setError] = useState<{ state: boolean; message: string |null }>({
        state: false,
        message: null
      });
    const [active, setActive] = useState<boolean | undefined>(false);
    const {userState, userDispatch} =useContext<any>(GlobalContext);
    const history =useHistory()
    useEffect(()=>{
      if(userState?.AdminLogin){
        history.push('/admin/dashboard')
        }

       if(userState?.AdminLoginError){
         setError({
             state: true,
             message: userState?.AdminLoginError?.response?.data.message
         })
         userDispatch({type: ADMINLOGIN_ERROR, payload: null})
       }
    },[userState?.AdminLogin, userState?.AdminLoginError])

    useEffect(()=>{
      if(form?.email.trim().length >0 || form?.password.trim().length>0){
        setActive(true)
      }
      else{
          setActive(false)
      }
    },[form])

    const handleSubmit = async(e: any)=>{
        e.preventDefault()
        setLoading(true)
        const user = {
            email: form?.email!,
            password: form?.password!
        }
        await AdminLogin(user)(userDispatch)
        setLoading(false)
    }

    return(
        <AuthLayout>
            <section className={size.width >= 768 ? classes.desktop : classes.mobile}>
                <form onSubmit={handleSubmit}>
                <h1>ADMINISTRATOR LOGIN</h1>
                {error && (
                    <p className={classes.error} >{error.message}</p>
                )}
                <IonRow>
                    <IonCol>
                    <InputCard
                    title={'Enter Your Email'}
                    setForm={setForm}
                    name={'email'}
                    type={'email'}
                    />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    <InputCard
                    title={'Enter Password'}
                    setForm={setForm}
                    name={'password'}
                    type={'password'}
                    icon={eye}
                    />
                    </IonCol>
                </IonRow>
                <IonRow className={classes.Btn}>
                <IonCol size="12">
                    <Btn
                        onClick={(e)=> e}
                        name="Submit"
                        loading ={loading}
                        match ={active}
                        />
                </IonCol>
                </IonRow>
                </form>
            </section>
        </AuthLayout>
    )
}

export default AdministratorLogin;