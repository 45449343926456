import { IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import StoreCard from '../../../components/cards/store';
import Filterbar from '../../../components/filterbar';
import Layout from '../../../components/Layout/user/Layout';
import { getStores } from '../../../context/actions/store';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';
import { GET_STORES_ERROR } from '../../../context/actions/types';
import { storeSchema } from '../../../types/store';
import Spinner from '../../../components/spinner';
import useStore from '../../../hooks/useStore';

const Stores = () => {
  const size = useContext(DeviceContext);
  const [stores, setStores] = useState<storeSchema[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const globalStore = useStore();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function fetch() {
      if (globalStore.storeState!.bulkStores) {
        setLoading(false);
        return setStores(globalStore.storeState!.bulkStores as storeSchema[]);
      }
      await getStores()(globalStore.storeDispatch!);
    }
    fetch();
  }, []);
  useEffect(() => {
    if (globalStore.storeState!.bulkStores) {
      setStores(globalStore.storeState!.bulkStores as storeSchema[]);
      setLoading(false);
    }
    if (globalStore.storeState!.bulkStoresError) {
      history.replace({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: globalStore.storeState!.bulkStoresError!?.response!?.data!
            ?.message
            ? globalStore.storeState!.bulkStoresError!?.response!?.data!
                ?.message
            : globalStore.storeState!.bulkStoresError!?.message,
          code: globalStore.storeState!.bulkStoresError.request.status
            ? globalStore.storeState!.bulkStoresError.request.status
            : globalStore.storeState!.bulkStoresError.status,
        },
      });
      globalStore.storeDispatch!({ type: GET_STORES_ERROR, payload: null });
    }
  }, [globalStore.storeState]);

  return (
    <Layout>
      <IonGrid className={classes.storesContainer}>
        <IonRow className={classes.allContent}>
          <IonCol>
            <div className={classes.head}>
              <div className={classes.title}>All Stores</div>
              <div
                className={classes.filterBar}
                style={{ width: size.width >= 568 ? '20%' : '60%' }}
              >
                <Filterbar action={setFilters} />
              </div>
            </div>
            <div
              className={classes.content}
              style={{ justifyContent: size.width < 568 ? 'center' : '' }}
            >
              {loading ? (
                <Spinner type="circles"></Spinner>
              ) : (
                stores
                  ?.filter((item) => {
                    return filters.length > 0
                      ? filters.includes(item.type)
                      : true;
                  })
                  .map((item, index) => {
                    return <StoreCard data={item} key={index} />;
                  })
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Layout>
  );
};

export default Stores;
