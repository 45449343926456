import React, { useCallback, useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Layout from '../../../components/Layout/user/Layout';
import { useLocation, useHistory } from 'react-router';
import { GlobalContext } from '../../../context/provider';
import { DeviceContext } from '../../../deviceType';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import StoreCard from './storeCard';
import ProductCard from './productCard';
import {
  addProductView,
  getSingleProduct,
} from '../../../context/actions/products';
import {
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_ERROR,
} from '../../../context/actions/types';
import RecoCard from './reccomendations';
import ProductDetailSkeleton from '../../../components/skeletons/product-details-page';
import { storeSchema } from '../../../types/store';
import { ProductType } from '../../../types/product';

const ProductDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const query = window.location.search.split('&')[0].split('=').slice(-1)[0];
  const size = useContext(DeviceContext);
  const { productState, productDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<ProductType>({} as ProductType);
  const [store, setStore] = useState<storeSchema>({} as storeSchema);

  useEffect(() => {
    setLoading(true);
    const id = { id: query };
    fetchItem(id);
  }, [query]);

  useEffect(() => {
    if (productState.getSingleProduct) {
      const res = productState.getSingleProduct;
      const { store, ...rest } = res;
      setItem(res);
      setStore(store);
      //
      addView(res.id);
    }
    if (productState.getSingleProductError) {
      history.push({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: productState.getSingleProductError!?.response!?.data!?.message
            ? productState.getSingleProductError!?.response!?.data!?.message
            : productState.getSingleProductError!?.message,
          code: productState.getSingleProductError.request.status
            ? productState.getSingleProductError.request.status
            : productState.getSingleProductError.status,
        },
      });
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
    }
    setLoading(false);
  }, [productState.getSingleProduct, productState.getSingleProductError]);

  async function fetchItem(e: { id: string }) {
    let state = productState.getSingleProduct;
    if (state && state.id === e.id) {
      setStore(state.store);
      return setItem(state);
    }
    await getSingleProduct(e.id)(productDispatch);
  }
  async function addView(productId: number) {
    await addProductView({ productId })(productDispatch);
  }

  return (
    <Layout>
      {loading === false ? (
        <div className={classes.holder}>
          {size.width > 768 && (
            <IonGrid className={classes.desktop}>
              <IonRow className={classes.container}>
                <IonCol className={classes.leftBar} size={'9'}>
                  <div className={classes.productCardHolder}>
                    <ProductCard data={item} />
                  </div>
                  <div className={classes.recomHolder}>
                    <RecoCard ref_product={item} />
                  </div>
                </IonCol>
                <IonCol className={classes.rightBar} size={'3'}>
                  <div className={classes.storeCardHolder}>
                    <StoreCard data={store!} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          {size.width <= 768 && (
            <IonGrid className={classes.mobile}>
              <IonRow>
                <div className={classes.productCardHolder}>
                  <ProductCard data={item} />
                </div>
                <div className={classes.recom}>
                  <RecoCard ref_product={item} />
                </div>
              </IonRow>
            </IonGrid>
          )}
        </div>
      ) : (
        <div className={classes.loading}>
          <ProductDetailSkeleton />
        </div>
      )}
    </Layout>
  );
};

export default ProductDetails;
