import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonPopover,
} from '@ionic/react';
import {
  eyeSharp,
  logOutSharp,
  addSharp,
  listSharp,
  settingsSharp,
  swapHorizontal,
  notificationsSharp,
  addCircle,
} from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { getCurrentUser } from '../../utils/getClientInfo';
import classes from './styles.module.scss';
import UserProfile from './userProfile';
import { useLocation } from 'react-router-dom';
import dash from '../../Assets/dashboard.svg';
import BecomeASeller from '../becomeSellerModal';
import { User } from '../../types/user';
import { authService } from '../../utils/authService';

const Menu = (props: {
  open: boolean;
  close: (e: any) => void;
  userDetails: User;
  openEvent: any;
}) => {
  const location = useLocation();
  const user = getCurrentUser();
  const isOnDashBoard = location.pathname.includes('dashboard');
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const sellerHasStore: boolean = user?.role === 'seller' && !!user?.stores?.length;

  const handleSellerMenuItemClick = (item: MenuItem) => {
    if (item.route === '/logout') {
      authService.logoutUser();
    } else if (item.isSwitch) {
      if (!sellerHasStore) {
        history.push('/dashboard/create-store');
      } else {
        history.push(isOnDashBoard ? item.route : '/dashboard');
      }
    } else {
      history.push(item.route);
    }
  };

  const handleUserMenuItemClick = async(item: object | any) => {
    if (item.route === '/become-vendor') {
      setIsModalOpen(true);
    } else if (item.route === '/logout') {
      authService.logoutUser();
    } else {
      history.push(item.route);
    }
  };

  
  const getInitials = (): string => {
    const first = props.userDetails?.firstName[0].toUpperCase();
    const last = props.userDetails?.lastName[0].toUpperCase();
    return first + last;
  };

  return (
    <IonPopover
      className={classes.menu}
      trigger="store-menu-trigger"
      triggerAction="click"
      reference="trigger"
    >
      <IonContent>
        
        {/* Become vendor modal */}
        <BecomeASeller
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
        
        <div className={classes.container}>
          <IonItem
            lines="full"
            className={classes.top}
            href="/#"
            detail={false}
          >
            <IonAvatar slot="start">
              <UserProfile color="#A94064" placeholder={getInitials()} />
            </IonAvatar>
            <IonLabel color="secondary">
              {props.userDetails?.firstName.charAt(0).toUpperCase() +
                props.userDetails?.firstName.slice(1)}{' '}
              {props.userDetails?.lastName.charAt(0).toUpperCase() +
                props.userDetails?.lastName.slice(1)}
            </IonLabel>
          </IonItem>
          <IonItemGroup>
            {user?.role === 'seller' &&
              sellerMenu?.map((item: any, index: any) => { return !item.disabled && (
                <IonItem
                  onClick={() => handleSellerMenuItemClick(item)}
                  key={index}
                  lines={item.route !== '/logout' ? 'full' : 'none'}
                  detail={false}
                  button
                >
                  {item.isSwitch ? (
                    <>
                      <IonIcon
                        slot="start"
                        icon={!sellerHasStore ? addCircle : item.icon}
                        color="primary"
                        size="default"
                      ></IonIcon>
                      <IonLabel>
                        {!sellerHasStore
                          ? 'Create Shop'
                          : isOnDashBoard
                          ? item.name
                          : 'Switch to Dashboard'}
                      </IonLabel>
                    </>
                  ) : (
                    <>
                      <IonIcon
                        slot="start"
                        icon={item.icon}
                        color="primary"
                        size="default"
                      ></IonIcon>
                      <IonLabel>{item.name}</IonLabel>
                    </>
                  )}
                </IonItem>
              )})}
            {user.role === 'user' &&
              userMenu.map((item: any, index: any) => (
                <IonItem
                  onClick={() => handleUserMenuItemClick(item)}
                  key={index}
                  lines={item.route !== '/logout' ? 'full' : 'none'}
                  detail={false}
                  button
                >
                  <IonIcon
                    slot="start"
                    icon={item.icon}
                    color="primary"
                    size="default"
                  ></IonIcon>
                  <IonLabel>{item.name}</IonLabel>
                </IonItem>
              ))}
          </IonItemGroup>
        </div>
      </IonContent>
    </IonPopover>
  );
};

export default Menu;

export type MenuItem = {
  route: string;
  icon?: any;
  name: string;
  isSwitch?: boolean;
  disabled?: boolean;
};

export const sellerMenu: MenuItem[] = [
  {
    name: 'Switch To Buying',
    route: '/',
    icon: eyeSharp,
    isSwitch: true,
    disabled: true,
  },
  { name: 'Add Item', route: '/dashboard/create-product', icon: addSharp },
  { name: 'Wish List', route: '/wishlist', icon: listSharp },
  { name: 'Notifications', route: '#', icon: notificationsSharp }, // remove before push
  { name: 'Settings', route: '/dashboard/settings', icon: settingsSharp },
  { name: 'Log out', route: '/logout', icon: logOutSharp },
];

export const userMenu: MenuItem[] = [
  { name: 'Account', route: '/#', icon: dash },
  { name: 'Become a Vendor', route: '/become-vendor', icon: swapHorizontal },
  // { name: 'Entry', route: '/#', icon: eyeSharp },
  // { name: 'Notifications', route: '/#', icon: notificationsSharp },
  // { name: 'Settings', route: '/#', icon: settingsOutline },
  { name: 'Log out', route: '/logout', icon: logOutSharp },
];
