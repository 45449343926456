import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { IonCard } from '@ionic/react';
import { getProducts, searchProducts } from '../../../context/actions/products';
import { GlobalContext } from '../../../context/provider';
import {
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_ERROR,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  getProductsParams,
} from '../../../context/actions/types';
import { useHistory, useLocation } from 'react-router';
import ProductBlock from '../../../components/cards/Product_Block';
import { ProductType } from '../../../types/product';

const RecoCard = (props: { ref_product: ProductType }) => {
  const { productState, productDispatch } = useContext<any>(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<ProductType>();
  const [sameStoreProducts, setSameStoreProducts] = useState<ProductType[]>([]);
  const [similarProducts, setSimilarProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    if (props.ref_product?.id) {
      setData(props.ref_product);
    }
  }, [props.ref_product]);

  useEffect(() => {
    if (data?.id) {
      const sameStoreProductsParams = {
        storeId: data?.store?.id?.toString()!,
        //type: data?.type!,
      };
      const similarProductsParams = {
        categories: [data?.type!],
        priceBelow: data?.price!.toString()!,
        priceAbove: data?.price!.toString()!,
      };
      fetchProductsFromSameStore(sameStoreProductsParams);
      fetchSimilarProducts(similarProductsParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // for samestore products
    if (productState.getProducts) {
      const data = productState.getProducts.data;
      setSameStoreProducts(data);
    }
    if (productState.getProductsError) {
      history.push({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: productState.getProductsError!?.response!?.data!?.message
            ? productState.getProductsError!?.response!?.data!?.message
            : productState.getProductsError!?.message,
          code: productState.getProductsError.request.status
            ? productState.getProductsError.request.status
            : productState.getProductsError.status,
        },
      });
      productDispatch({ type: GET_PRODUCTS_ERROR, payload: null });
    }

    // for similar products
    if (productState.searchProducts) {
      const data = productState.searchProducts.data;
      setSimilarProducts(data);
    }
    if (productState.searchProductsError) {
      history.push({
        pathname: '/err',
        state: {
          from: location.pathname,
          err: productState.searchProductsError!?.response!?.data!?.message
            ? productState.searchProductsError!?.response!?.data!?.message
            : productState.searchProductsError!?.message,
          code: productState.searchProductsError.request.status
            ? productState.searchProductsError.request.status
            : productState.searchProductsError.status,
        },
      });
      productDispatch({ type: SEARCH_PRODUCTS_ERROR, payload: null });
    }
  }, [
    productState.getProducts,
    productState.getProductsError,
    productState.searchProducts,
    productState.searchProductsError,
  ]);

  useEffect(() => {
    // console.log('1');
  }, [similarProducts, sameStoreProducts]);

  async function fetchProductsFromSameStore(e: {
    storeId: string;
    type?: string;
  }) {
    await getProducts(e)(productDispatch);
  }
  async function fetchSimilarProducts(e: getProductsParams) {
    //consider adding a means for action caching, example in React pullState
    await searchProducts(e)(productDispatch);
  }
  return (
    <IonCard className={classes.recomCard}>
      <div
        className={classes.sameStore}
        style={{ display: sameStoreProducts.length === 0 ? 'none' : 'block' }}
      >
        <div className={classes.label}>More Items from This Store</div>
        <div className={classes.list}>
          {sameStoreProducts!
            ?.filter((item: any) => {
              return item.id !== Number(props.ref_product.id);
            })
            .map((item: any, index) => {
              return (
                <div key={index} className={classes.listItem}>
                  <ProductBlock data={item} />
                </div>
              );
            })}
        </div>
      </div>
      {/** similar products from other stores disabled */}
      <div className={classes.similar} style={{ display: 'none' }}>
        <div className={classes.label}>You May Also Like</div>
        <div className={classes.list}>
          {similarProducts!
            ?.filter((item: any) => {
              return item.id !== Number(props.ref_product.id);
            })
            .map((item: any, index) => {
              return (
                <div key={index} className={classes.listItem}>
                  <ProductBlock data={item} />
                </div>
              );
            })}
        </div>
      </div>
    </IonCard>
  );
};

export default RecoCard;
