import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import PriceRangeInput from '../priceRangeInput';
import { getProductTypes } from '../../context/actions/products';
import { getProductsParams } from '../../context/actions/types';
import { GlobalContext } from '../../context/provider';

import classes from './styles.module.scss';

function paramsReducer(
  state: any,
  action: { type: string; payload: any }
): getProductsParams {
  switch (action.type) {
    case 'addCategory':
      return { ...state, categories: [...state.categories!, action.payload] };
    case 'removeCategory':
      const categories = state.categories!.filter(
        (i: any) => i !== action.payload
      );
      return { ...state, categories };
    case 'addLocation':
      return { ...state, location: [...state.location!, action.payload] };
    case 'removeLocation':
      const locations = state.location!.filter(
        (i: any) => i !== action.payload
      );
      return { ...state, location: locations };
    case 'setPriceRange':
      return { ...state, price: action.payload };
    default:
      return state;
  }
}

const Filters = (props: {
  setParams: React.Dispatch<any>;
  params: { local: any; extracted: getProductsParams };
  categories: boolean;
  locations: boolean;
  priceRange: boolean;
  reducer: boolean;
}) => {
  const history = useHistory();
  const path = useLocation();
  const inputRef = useRef<any>();
  const { location, priceAbove, priceBelow, ...rest } = props.params.extracted;
  const { productTypes, productTypesDispatch } = useContext<any>(GlobalContext);
  const [categories, setCategories] = useState<string[]>([]);
  const [params, paramsDispatch] = useReducer(paramsReducer, {
    categories: [],
    location: props.params.local.location
      ? [...props.params.local.location]
      : [],
    ...rest,
  });

  useEffect(() => {
    async function fetchTypes() {
      await getProductTypes()(productTypesDispatch);
    }
    fetchTypes();
  }, []);

  useEffect(() => {
    if (productTypes.productTypes != null) {
      setCategories(productTypes.productTypes.data);
    }
    if (productTypes.productTypesError != null) {
      history.replace({
        pathname: '/err',
        state: {
          from: path.pathname,
          err: productTypes.productTypesError!?.response!?.data!?.message
            ? productTypes.productTypesError!?.response!?.data!?.message
            : productTypes.productTypesError!?.message,
          code: productTypes.productTypesError.request.status,
        },
      });
    }
  }, [productTypes]);

  useEffect(() => {
    updateParams();
  }, [params]);

  function updateParams() {
    if (props.reducer) {
      props.setParams({
        type: 'setFilters',
        payload: {
          ...params,
        },
      });
    }
    if (!props.reducer) {
      props.setParams((previous: any) => {
        return {
          ...previous,
          ...params,
        };
      });
    }
  }
  return (
    <IonGrid className={classes.filtersGrid}>
      {props.categories && (
        <IonRow className={classes.categories}>
          <IonCol size={'12'}>
            <IonLabel>Category</IonLabel>
            <ul className={classes.categoriesList}>
              {categories.map((item, index) => {
                return (
                  <li key={index}>
                    <label htmlFor={String(index)}>{item}</label>
                    <input
                      ref={inputRef}
                      type={'checkbox'}
                      checked={params.categories!.includes(item)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          paramsDispatch({
                            type: 'addCategory',
                            payload: item,
                          });
                        }
                        if (!e.target.checked) {
                          paramsDispatch({
                            type: 'removeCategory',
                            payload: item,
                          });
                        }
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </IonCol>
        </IonRow>
      )}

      {props.locations && (
        <IonRow className={classes.locations}>
          <IonCol size={'12'}>
            <IonLabel>Location</IonLabel>
            <ul className={classes.locationList}>
              {location!.map((item, index) => {
                return (
                  <li key={index}>
                    <label htmlFor={String(index)}>{item}</label>
                    <input
                      ref={inputRef}
                      type={'checkbox'}
                      checked={params.location!.includes(item)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          paramsDispatch({
                            type: 'addLocation',
                            payload: item,
                          });
                        }
                        if (!e.target.checked) {
                          paramsDispatch({
                            type: 'removeLocation',
                            payload: item,
                          });
                        }
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </IonCol>
        </IonRow>
      )}
      {props.priceRange && (
        <IonRow className={classes.price}>
          <IonCol size={'12'}>
            <PriceRangeInput
              range={{
                max: Number(priceBelow),
                min: Number(priceAbove),
              }}
              setPrice={paramsDispatch}
            />
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default Filters;
