import jwt_decode from 'jwt-decode';

export type DecodedToken = {
  exp: number;
  iat: string;
  role: string;
  id: number;
  stores?: { id: number; name: string; slug: string }[];
};

export const authService = {
  getToken() {
    return localStorage.getItem('token');
  },
  
  decodeToken() {
    if(this.getToken()){
      return jwt_decode<DecodedToken>(this.getToken() as string);
    }
    return null;
  },

  isLoggedIn() {
    if (this.getToken()){

      return this.isExpired()? false : true;
    }

    return false;
  },

  isExpired() {
    const currentDate = Math.floor(Date.now() / 1000); // Current time in seconds (rounded down)
    const decodedToken = this.decodeToken();
    const expired = decodedToken!?.exp < currentDate;
    console.log(currentDate, decodedToken!?.exp, expired)

    // const expired = isAfter(Date.now(), fromUnixTime(decodedToken!?.exp));

    if (expired) {
      this.logoutUser()
      return true;
    } else {
      return false;
    }
  },

  getCurrentUser() {
    const decodedToken = this.decodeToken() as DecodedToken;

    const _isLogged = this.isLoggedIn()
    if(_isLogged){
      return decodedToken
    }

    return {id:0}
  },

  logoutUser() {
    localStorage.clear();
    window.location.replace('/');
  },

  redirectUser() {
    let referrer = window.location.pathname;
    if(referrer === '/login') referrer = '/'

    this.logoutUser();
    localStorage.setItem('locationReferrer', referrer);
    window.location.replace('/login');
  }
};