import { useEffect, useState } from "react";
import { FaqsType } from "../../types/faqs";
import classes from "./styles.module.scss";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

const FaqsItem = ({ title, content, position }: FaqsType) => {
  const [toggle, setToggle] = useState(false);
  const style = { color: "#0A2B45", fontSize: "1.5em" };

  useEffect(() => {
    if (position === 0) {
      setToggle(!toggle);
    }
  }, []);
  const toggler = () => {
    setToggle(!toggle);
  };

  return (
    <div className={classes.resetdiv}>
      <div className={classes.reset}>
        <h4>{title}</h4>
        <div onClick={toggler}>
          {!toggle ? (
            <HiChevronDown style={style} />
          ) : (
            <HiChevronUp style={style} />
          )}
        </div>
      </div>
      {toggle ? (
        <div>
          {content.map((item) => (
            <p>{item}</p>
          ))}
        </div>
      ) : (
        <></>
      )}
      <hr className={classes.lines} />
    </div>
  );
};
export default FaqsItem;
