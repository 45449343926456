import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

const colourPalette = [
  '#7E115A',
  '#115A5A',
  '#9E9E4C',
  '#54FCD8',
  '#5A115A',
  '#9D4402',
  '#0A452B',
  '#450A2B',
  '#450A2B',
  '#9E4B4C',
  '#D854FC',
  '#44029D',
  '#FC54F9',
  '#452B0A',
  '#54D8FC',
  '#7E115A',
  '#47EB47',
  '#449D02',
  '#9E4C9E',
  '#EB47EB',
  '#5A7E11',
  '#47EBEB',
  '#2B450A',
  '#029D44',
];

const PChart = ({ data }: { data: { name: string; value: number }[] }) => {
  const [activeIndex, setActiveIndex] = useState<any>();
  const [activeGroup, setActiveGroup] = useState<any>();
  const [_data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(data);
  }, [data]);

  const customLabel = ({ name, index }: any) => {
    if (activeGroup && name === activeGroup.name) {
      setActiveIndex(index);
      return;
    }
    return name;
  };
  return (
    <div className={classes.pchart}>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <PieChart width={400} height={400}>
          {activeGroup && (
            <>
              <text
                x={activeGroup.cx}
                y={activeGroup.cy}
                dy={5}
                textAnchor="middle"
                fill={activeGroup.fill}
                lengthAdjust={'spacingAndGlyphs'}
                textLength={80}
              >
                {activeGroup.name}
              </text>
              <text
                x={activeGroup.cx}
                y={activeGroup.cy}
                dy={20}
                textAnchor="middle"
                fontSize={10}
                fill="rgba(15, 50, 77,0.7)"
              >
                {`(Rate ${(activeGroup.percent * 100).toFixed(2)}%)`}
              </text>
            </>
          )}
          <Pie
            data={_data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={65}
            label={customLabel}
            legendType="circle"
            activeIndex={activeIndex}
            onMouseEnter={(e) => {
              setActiveGroup(e);
            }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colourPalette[index % colourPalette.length]}
              />
            ))}
          </Pie>
          {activeGroup && (
            <Pie
              data={[activeGroup.payload.payload]}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={75}
              fill="#A94064"
              startAngle={activeGroup.startAngle}
              endAngle={activeGroup.endAngle}
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PChart;
