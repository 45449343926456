import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import SellerMenu from '../menu';
import SellerNav from '../nav';
import classes from './styles.module.scss';
import { GlobalContext } from '../../../../context/provider';

const Desktop = ({ children }: any) => {
  const { userState } = useContext<any>(GlobalContext);
  const userDetails: any = userState.getUser;

  return (
    <IonGrid className={classes.desktop}>
      <IonRow className={classes.topBar}>
        <IonCol size="12" className={classes.nav}>
          <SellerNav userDetails={userDetails} />
        </IonCol>
      </IonRow>
      <IonRow className={classes.container}>
        <IonCol size="0.6" className={classes.sidebar}>
          <SellerMenu />
        </IonCol>
        <IonCol size="11.4" className={classes.cont}>
          {children}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Desktop;
