import { axiosInstance } from '../../utils/axios';
import { Dispatch } from 'react';
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  getProductsParams,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_ERROR,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_ERROR,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  Product,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  GET_TRENDING_PRODUCTS,
  GET_TRENDING_PRODUCTS_ERROR,
  GET_WISHLIST,
  GET_WISHLIST_ERROR,
  ADD_TO_WISHLIST,
  ADD_TO_WISHLIST_ERROR,
  ADD_PRODUCT_VIEW_ERROR,
  ADD_PRODUCT_VIEW,
  CreateProduct,
  ADD_BULK_PRODUCTS,
  ADD_BULK_PRODUCTS_ERROR,
} from './types';
import { getCurrentStore } from '../../utils/currentStore';

export const getProductTypes =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const data = await axiosInstance.get('v1/products/types');
      dispatch({ type: GET_PRODUCT_TYPES, payload: data.data });
    } catch (error: any) {
      dispatch({ type: GET_PRODUCT_TYPES_ERROR, payload: error });
    }
  };

export const createProduct =
  (payload: any) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const store = getCurrentStore();
      payload.store = Number(store);
      const res = await axiosInstance.post('v1/products', payload);
      dispatch({ type: CREATE_PRODUCT, payload: res?.data?.data });
    } catch (error: any) {
      dispatch({
        type: CREATE_PRODUCT_ERROR,
        payload: error?.response?.data?.message || 'something went wrong',
      });
    }
  };

export const updateProduct =
  (payload: { id: string; data: any }) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const store = getCurrentStore();
      payload.data.store = Number(store);
      const res = await axiosInstance.put(
        `v1/products/${payload.id}`,
        payload.data
      );
      dispatch({ type: UPDATE_PRODUCT, payload: res.data });
    } catch (error: any) {
      dispatch({
        type: UPDATE_PRODUCT_ERROR,
        payload: error?.response?.data?.messsage || 'something went wrong',
      });
    }
  };

export const getProducts =
  (payload: getProductsParams) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get('v1/products', { params: payload });
      dispatch({ type: GET_PRODUCTS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR, payload: error });
    }
  };

export const deleteProducts =
  (payload: { id: string }) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.delete(`v1/products/${payload.id}`);
      dispatch({ type: DELETE_PRODUCT, payload: res.data });
    } catch (error) {
      dispatch({ type: DELETE_PRODUCT_ERROR, payload: error });
    }
  };

export const getSingleProduct =
  (payload: string) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      if (!payload) {
        throw new Error();
      }
      const res = await axiosInstance.get(`v1/products/${payload}`);
      dispatch({ type: GET_SINGLE_PRODUCT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: error });
    }
  };

export const searchProducts =
  (payload: getProductsParams) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const queryParams = payload as {
        storeId?: string;
        price?: string;
        location?: string;
        categories?: string;
        text: string;
      };
      if (payload.location && payload.location.length > 0) {
        queryParams.location = payload.location.toString();
      } else {
        delete queryParams.location;
      }
      if (payload.categories && payload.categories.length > 0) {
        queryParams.categories = payload.categories
          .filter((item) => {
            return item != null;
          })
          .toString();
      } else {
        delete queryParams.categories;
      }
      const res = await axiosInstance.get('v1/search/products', {
        params: queryParams,
      });

      dispatch({ type: SEARCH_PRODUCTS, payload: res.data });
    } catch (error) {
      dispatch({ type: SEARCH_PRODUCTS_ERROR, payload: error });
    }
  };

export const getTrendingProducts =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get('/v1/most-searched?limit=10');

      dispatch({ type: GET_TRENDING_PRODUCTS, payload: res.data.data });
    } catch (error: any) {
      dispatch({ type: GET_TRENDING_PRODUCTS_ERROR, payload: error });
    }
  };

export const addToWishList =
  (payload: any) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.post('v1/products/bookmark', {
        productId: payload,
      });
      dispatch({ type: ADD_TO_WISHLIST, payload: res.data });
    } catch (error) {
      dispatch({ type: ADD_TO_WISHLIST_ERROR, payload: error });
    }
  };

export const getWishList =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get('v1/bookmarks');

      dispatch({ type: GET_WISHLIST, payload: res.data.data });
    } catch (error) {
      dispatch({ type: GET_WISHLIST_ERROR, payload: error });
    }
  };

export const addProductView =
  (payload: { productId: number }) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.post('/v1/products/view', { ...payload });
      return dispatch({
        type: ADD_PRODUCT_VIEW,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_VIEW_ERROR,
        payload: error,
      });
    }
  };

export const addBulkProducts =
  (payload: File) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const store = getCurrentStore();
      const form = new FormData();
      form.append('files', payload);
      const res = await axiosInstance.post(
        `v1/bulk-products?storeId=${store}`,
        form
      );
      dispatch({ type: ADD_BULK_PRODUCTS, payload: res?.data?.data });
    } catch (error: any) {
      dispatch({
        type: ADD_BULK_PRODUCTS_ERROR,
        payload: error?.response?.data?.message || 'something went wrong',
      });
    }
  };
