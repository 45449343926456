import React from 'react';
import classes from './styles.module.scss';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const SmoothLineChart = (
  props: {
    x: string;
    y: string;
    data: { x: string; y: string }[];
    showKey: boolean;
    smooth?: boolean;
  } = { x: '', y: '', data: [], showKey: true, smooth: false}
) => {
  const col_data = props.data.map((item) => {
    return {
      name: props.x,
      [props.x]: item.x,
      [props.y]: item.y,
    };
  });
  return (
    <div className={classes.chart}>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <LineChart
          data={col_data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={props.x} />
          <YAxis />
          <Tooltip />
          {props.showKey && <Legend />}
          <Line
            type={props.smooth ? 'monotone' : 'linear'}
            dataKey={props.y}
            stroke="#0f324d"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SmoothLineChart;
